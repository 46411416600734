import { useEffect, useRef } from 'react';

const useLiveChat = () => {
  const isInitializedRef = useRef(false);

  useEffect(() => {
    if (!isInitializedRef.current) {
      if (window.LiveChatWidget) {
        window.LiveChatWidget.init();
        isInitializedRef.current = true;
      }
    }
  }, []);
};

export { useLiveChat };

import { z } from 'zod';

const editSchema = {
  firstname: z.string().min(1, { message: 'Required' }),
  lastname: z.string().min(1, { message: 'Required' })
};

const editPasswordSchema = {
  password: z.string().min(1, { message: 'Required' }),
  newPassword: z.string().min(8),
  confirm: z.string().min(8)
};

export { editSchema, editPasswordSchema };

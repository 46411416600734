const formatFileSize = (bytes) => {
  if (bytes === 0) return '0 Bytes';
  const k = 1024;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i];
};

const getMimeTypeLabel = (mimeType) => {
  const mimeTypeMap = {
    // Document formats
    'application/pdf': 'PDF',
    'application/msword': 'Word Document',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'Word Document',
    'application/vnd.ms-excel': 'Excel Spreadsheet',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'Excel Spreadsheet',
    'application/vnd.ms-powerpoint': 'PowerPoint Presentation',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'PowerPoint Presentation',
    'text/plain': 'Text File',
    'application/rtf': 'Rich Text Format',

    // formats
    'image/jpeg': 'JPEG',
    'image/png': 'PNG',
    'image/gif': 'GIF',
    'image/svg+xml': 'SVG',
    'image/webp': 'WebP',
    'image/tiff': 'TIFF',
    'image/bmp': 'BMP',

    // Audio formats
    'audio/mpeg': 'MP3 Audio',
    'audio/wav': 'WAV Audio',
    'audio/ogg': 'OGG Audio',
    'audio/midi': 'MIDI Audio',
    'audio/x-m4a': 'M4A Audio',

    // Video formats
    'video/mp4': 'MP4 Video',
    'video/mpeg': 'MPEG Video',
    'video/quicktime': 'QuickTime Video',
    'video/x-msvideo': 'AVI Video',
    'video/x-ms-wmv': 'Windows Media Video',
    'video/webm': 'WebM Video',

    // Archive formats
    'application/zip': 'ZIP Archive',
    'application/x-rar-compressed': 'RAR Archive',
    'application/x-7z-compressed': '7-Zip Archive',
    'application/x-tar': 'TAR Archive',
    'application/gzip': 'GZIP Archive',

    // Programming and markup languages
    'text/html': 'HTML File',
    'text/css': 'CSS File',
    'application/javascript': 'JavaScript File',
    'application/json': 'JSON File',
    'application/xml': 'XML File',

    // Other common formats
    'application/vnd.android.package-archive': 'Android Package',
    'application/x-apple-diskimage': 'Apple Disk',
    'application/vnd.visio': 'Visio Document',
    'application/x-photoshop': 'Photoshop Document',
    'application/illustrator': 'Adobe Illustrator File',

    // CSV
    'text/csv': 'CSV'
  };

  return mimeTypeMap[mimeType] || 'File'; // Default to 'File' if mime type is not found
};

const isPreviewable = (mimeType) => {
  const previewableMimeTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/svg+xml', 'image/webp', 'image/tiff', 'image/bmp', 'application/pdf'];
  return previewableMimeTypes.includes(mimeType);
};

const resizeImage = async (file, maxWidth = 800, maxHeight = 800) => {
  return new Promise((resolve) => {
    if (file.type === 'image/svg+xml') {
      // SVG doesn't need resizing
      resolve(file);
      return;
    }

    const reader = new FileReader();
    reader.onload = (event) => {
      const img = new Image();
      img.onload = () => {
        const canvas = document.createElement('canvas');
        let width = img.width;
        let height = img.height;

        if (width > height) {
          if (width > maxWidth) {
            height *= maxWidth / width;
            width = maxWidth;
          }
        } else {
          if (height > maxHeight) {
            width *= maxHeight / height;
            height = maxHeight;
          }
        }

        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        const outputType = file.type === 'image/png' ? 'image/png' : 'image/jpeg';
        const quality = outputType === 'image/png' ? 1 : 0.8;

        canvas.toBlob(
          (blob) => {
            resolve(new File([blob], file.name, { type: outputType, lastModified: Date.now() }));
          },
          outputType,
          quality
        );
      };
      img.src = event.target.result;
    };
    reader.readAsDataURL(file);
  });
};

export { formatFileSize, getMimeTypeLabel, isPreviewable, resizeImage };

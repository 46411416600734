import { createSlice } from '@reduxjs/toolkit';

const calculationsInitialState = {
  'sub-field': {},
  field: {},
  'field-group': {},
  bucket: {},
  'bucket-group': {},
  total: {},
  'indirect-cost': {},
  'total-cost': {}
};

const { actions, reducer } = createSlice({
  name: 'estimateCalculations',
  initialState: calculationsInitialState,
  reducers: {
    setCalculations: (state, action) => {
      state['sub-field'] = action.payload.subFieldCalculations;
      state.field = action.payload.fieldCalculations;
      state['field-group'] = action.payload.fieldGroupCalculations;
      state['bucket-group'] = action.payload.bucketGroupCalculations;
      state['bucket'] = action.payload.bucketCalculations;
      state['indirect-cost'] = action.payload.indirectCostCalculations;
      state['total-cost'] = action.payload.totalCalculations;
    },
    resetEstimate: () => {
      return calculationsInitialState;
    }
  }
});

export { actions, reducer };

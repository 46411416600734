import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { Loader2 } from 'lucide-react';

import { editSchema } from './_validators';
import { Button } from '@/components/ui/button';
import { Error } from '@/components/ui/error';
import { DialogFooter } from '@/components/ui/dialog';
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from '@/components/ui/form';
import { Input } from '@/components/ui/input';

import { useSelf, usePutSelf } from '@/data/user';

const formSchema = z.object(editSchema);

const EditUserForm = ({ setOpen }) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [apiError, setApiError] = useState('');
  const self = useSelf();
  const putSelf = usePutSelf();
  const { firstname, lastname } = self.data;
  const form = useForm({
    resolver: zodResolver(formSchema),
    defaultValues: {
      firstname,
      lastname
    }
  });

  const onSubmit = async (values) => {
    try {
      setIsSubmitting(true);
      setApiError('');

      const response = await putSelf(values);
      await self.mutate(response, false);
      setIsSubmitting(false);
      setOpen(false);
    } catch (err) {
      setIsSubmitting(false);
      setApiError(err.message);
    }
  };

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-2">
        <FormField
          control={form.control}
          name="firstname"
          render={({ field }) => (
            <FormItem>
              <FormLabel>First Name</FormLabel>
              <FormControl>
                <Input {...field} autoComplete="off" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="lastname"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Last Name</FormLabel>
              <FormControl>
                <Input {...field} autoComplete="off" />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        {apiError && <Error message={apiError} />}
        <DialogFooter>
          <Button type="submit" disabled={isSubmitting}>
            {isSubmitting && <Loader2 className="animate-spin" />}Save Profile
          </Button>
        </DialogFooter>
      </form>
    </Form>
  );
};

export default EditUserForm;

import axios from 'axios';
import useSWR from 'swr';
import { useState } from 'react';
import { getApiUrl } from '@/helpers/api';
import { useToast } from '@/helpers/toast';
import { resizeImage } from '@/pages/document/_helpers';
const useCompany = () => {
  const url = `${getApiUrl('v2')}/company`;
  const swr = useSWR(url, async () => {
    try {
      const result = await axios.get(url);
      return result.data;
    } catch (err) {
      throw Error(err);
    }
  });

  return swr;
};

const usePutCompany = () => {
  const { toast } = useToast();
  const putCompany = async (payload) => {
    try {
      const url = `${getApiUrl('v2')}/company`;
      const result = await axios.put(url, payload);
      return result.data;
    } catch (error) {
      toast({ title: 'Error', description: 'An error occurred updating company', variant: 'destructive' });
      throw Error(error);
    }
  };

  return putCompany;
};

const useUploadCompanyLogo = () => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState();
  const putCompany = usePutCompany();
  const { toast } = useToast();

  const uploadCompanyLogo = async (file, onUploadSuccess) => {
    try {
      const resizedFile = await resizeImage(file, 600, 600);

      // Get the signed url from our server
      const signature = await axios.get(`${getApiUrl('v2')}/company/logo/upload-signature?fileType=${resizedFile.type}&fileName=${resizedFile.name}`);

      // Upload the file to the signed google url
      await axios.put(signature.data.signedUrl, resizedFile, {
        headers: {
          'Content-Type': resizedFile.type
        },
        withCredentials: false,
        onUploadProgress: (progressEvent) => {
          setProgress(Math.round((progressEvent.loaded * 100) / progressEvent.total));
        },
        onError: (error) => {
          setError(error.message || 'An error occurred during upload');
          throw error;
        }
      });
      const newCompany = await putCompany({ logo_url: signature.data.publicUrl });
      onUploadSuccess(newCompany);
      setProgress(0);
      setError(null);
    } catch (error) {
      toast({ title: 'Error', description: 'An error occurred during upload', variant: 'destructive' });
      setError(error.message || 'An error occurred during upload');
      setProgress(0);
      throw error;
    }
  };

  return { progress, error, uploadCompanyLogo };
};

const useIsCompanySSO = () => {
  const company = useCompany();
  return company.data.login_type === 'sso';
};

export { useCompany, usePutCompany, useIsCompanySSO, useUploadCompanyLogo };

import { useAuth0 } from '@auth0/auth0-react';
import { useRollbarPerson } from '@rollbar/react';

const getRollbarEnvironment = () => {
  if (typeof window === 'undefined' || window.location.origin.includes('app.zebel.io')) {
    return 'production';
  } else if (window.location.origin.includes('app.stage.zebel.io')) {
    return 'stage';
  } else {
    return 'development';
  }
};

const getRollbarConfig = () => {
  const environment = getRollbarEnvironment();
  return {
    accessToken: 'ebd691b52e7742dca29036d0e9d53a34',
    environment,
    captureUncaught: true,
    captureUnhandledRejections: true,
    payload: {
      environment,
      client: {
        javascript: {
          // eslint-disable-next-line no-undef
          code_version: process.env.VITE_APP_VERSION,
          source_map_enabled: true,
          guess_uncaught_frames: true
        }
      }
    },
    transform: (payload) => {
      var trace = payload.body.trace;
      if (trace && trace.frames) {
        for (var i = 0; i < trace.frames.length; i++) {
          var filename = trace.frames[i].filename;
          if (filename) {
            trace.frames[i].filename = `https://dynamichost${filename.replace(window.location.origin, '')}`;
          }
        }
      }
    },
    checkIgnore: (isUncaught, error, payload) => {
      // Allow all errors from dev and prod urls
      const { url } = payload.request;
      if (url.includes('app.zebel.io') || url.includes('app.dev.zebel.io') || url.includes('app.stage.zebel.io')) {
        return false;
      }

      // Ignore all errors from local dev envs
      return true;
    }
  };
};

const useRollbar = () => {
  const { user } = useAuth0();

  useRollbarPerson({
    username: user?.email || '',
    companyName: user?.['zebel.io/company_name'] || '',
    companyUid: user?.['zebel.io/company_uid'] || '',
    userUid: user?.['zebel.io/user_uid'] || ''
  });
};

export { getRollbarConfig, useRollbar };

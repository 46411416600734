import axios from 'axios';

const getBaseAPIUrl = () => {
  if (window.location.origin.includes('localhost')) return 'https://app.eng.zebel.local:5000';
  if (window.location.origin.includes('app.eng.zebel.local')) return 'https://app.eng.zebel.local:5000';
  if (window.location.origin.includes('app.zebel.local')) return 'https://api.dev.zebel.io';
  if (window.location.origin.includes('app.zebel.io')) return 'https://api.zebel.io';
  if (window.location.origin.includes('app.dev.zebel.io')) return 'https://api.dev.zebel.io';
  if (window.location.origin.includes('app.stage.zebel.io')) return 'https://api.stage.zebel.io';
};

const baseUrl = getBaseAPIUrl();

export const getUserInfo = async (authorization_code) => {
  let result = null;

  const url = `${baseUrl}/connectors/procore/getUserInfo`;

  try {
    result = await axios.post(url, { authorization_code: authorization_code });

    if (result.status === 200) return result.data;
    else return null;
  } catch (error) {
    return error.response;
  }
};

export const getCompany = async () => {
  let result = null;
  // const accessToken = sessionStorage.getItem("procoreAccessToken");
  const url = `${baseUrl}/connectors/procore/companies`;

  try {
    // result = await axios.get(url, { headers: { Authorization: `Bearer ${accessToken}` } });
    result = await axios.get(url);

    if (result.status === 200) return result.data;
    else return null;
  } catch (error) {
    return error.response;
  }
};

export const setUsersCompany = async (selectedCompanyId) => {
  let result = null;

  const url = `${baseUrl}/connectors/procore/setUsersCompany`;

  try {
    result = await axios.post(url, { user_company_id: selectedCompanyId });

    if (result.status === 200) return result.data;
    else return null;
  } catch (error) {
    return error.response;
  }
};

export const signOutProcoreUser = async () => {
  let result = null;

  const url = `${baseUrl}/connectors/procore/signOutProcoreUser`;

  try {
    result = await axios.delete(url);

    if (result.status === 200) return result.data;
    else return null;
  } catch (error) {
    return error.response;
  }
};

export const isUserLoggedIn = async () => {
  let result = null;

  const url = `${baseUrl}/connectors/procore/user/login_status`;

  try {
    result = await axios.get(url);

    if (result.status === 200) return result.data;
    else return false;
  } catch (error) {
    return false;
  }
};

export const getProjects = async () => {
  let result = null;

  const url = `${baseUrl}/connectors/procore/company/projects`;

  try {
    result = await axios.get(url);

    if (result.status === 200) return result.data;
    else return null;
  } catch (error) {
    return error.response;
  }
};

export const getProjectInfo = async (project_id) => {
  let result = null;

  const url = `${baseUrl}/connectors/procore/company/projects/${project_id}`;

  try {
    result = await axios.get(url);

    if (result.status === 200) return result.data;
    else return null;
  } catch (error) {
    return error.response;
  }
};

export const getProjectBudgetColumns = async (project_id, budget_view_id) => {
  let result = null;

  const url = `${baseUrl}/connectors/procore/company/projects/${project_id}/views/${budget_view_id}/columns`;

  try {
    result = await axios.get(url);

    if (result.status === 200) return result.data;
    else return null;
  } catch (error) {
    return error.response;
  }
};

export const getProjectCostCodes = async (projectId) => {
  let result = null;
  // const accessToken = sessionStorage.getItem("procoreAccessToken");

  const url = `${baseUrl}/connectors/procore/projects/${projectId}/cost_codes`;

  try {
    result = await axios.get(url);

    if (result.status === 200) return result.data;
    else return null;
  } catch (error) {
    return error.response;
  }
};

export const getProjectBudget = async (projectId, budget_view_id) => {
  let result = null;
  // const accessToken = sessionStorage.getItem("procoreAccessToken");
  const url = `${baseUrl}/connectors/procore/projects/${projectId}/view/${budget_view_id}/budget`;

  try {
    result = await axios.get(url);

    if (result.status === 200) return result.data;
    else return null;
  } catch (error) {
    return error.response;
  }
};

export const getProjectBudgetViews = async (projectId) => {
  let result = null;

  const url = `${baseUrl}/connectors/procore/projects/${projectId}/budget/views`;

  try {
    result = await axios.get(url);

    if (result.status === 200) return result.data;
    else return null;
  } catch (error) {
    return error.response;
  }
};

export const getProjectBudgetRows = async (projectId, budget_view_id) => {
  let result = null;
  // const accessToken = sessionStorage.getItem("procoreAccessToken");

  const url = `${baseUrl}/connectors/procore/projects/${projectId}/view/${budget_view_id}/budget/rows`;

  try {
    result = await axios.get(url);

    if (result.status === 200) return result.data;
    else return null;
  } catch (error) {
    return error.response;
  }
};

export const importProject = async (importFormData) => {
  let result = null;

  const url = `${baseUrl}/connectors/procore/company/projects/import/${importFormData?.projectToImportingId}`;

  try {
    result = await axios.post(url, importFormData);

    if (result.status === 200) return result.data;
    else return null;
  } catch (error) {
    return error.response;
  }
};

export const getImportedProjectsIds = async () => {
  let result = null;

  const url = `${baseUrl}/connectors/procore/company/projects/imported/`;

  try {
    result = await axios.get(url);

    if (result.status === 200) return result.data;
    else return null;
  } catch (error) {
    return error.response;
  }
};

export const getProgress = async () => {
  let result = null;

  const url = `${baseUrl}/start_counting`;

  try {
    result = await axios.get(url);

    return result;
  } catch (error) {
    return error.response;
  }
};

export default {
  getUserInfo,
  getCompany,
  setUsersCompany,
  signOutProcoreUser,
  isUserLoggedIn,
  getProjects,
  getProjectInfo,
  getProjectBudgetColumns,
  getProjectCostCodes,
  getProjectBudget,
  getProjectBudgetViews,
  getProjectBudgetRows,
  importProject,
  getImportedProjectsIds,
  getProgress
};

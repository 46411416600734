import { Box, CircularProgress } from '@mui/material';

const Loading = ({ height = 250, size = 16 }) => {
  return (
    <Box height={height} display="flex" alignItems="center" justifyContent="center">
      <CircularProgress size={size} />
    </Box>
  );
};

export default Loading;

import { KeyboardArrowDown } from '@mui/icons-material';

const theme = {
  palette: {
    background: {
      default: '#bf0000'
    },
    primary: {
      main: '#1b3269'
    },
    secondary: {
      main: '#515151'
    }
  },
  components: {
    Mui: {
      styleOverrides: {
        root: {
          '&.Mui-focused': {
            backgroundColor: 'red'
          }
        }
      }
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
        size: 'small'
      },
      styleOverrides: {
        root: {
          outlineWidth: '0px'
        }
      }
    },
    MuiCircularProgress: {
      defaultProps: {
        size: 22
      }
    },
    MuiSelect: {
      defaultProps: {
        size: 'small',
        IconComponent: KeyboardArrowDown
      }
    },
    MuiButton: {
      defaultProps: {
        disableElevation: true
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontSize: '10px'
        }
      }
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '12px',
          fontWeight: '400',
          lineHeight: '15px',
          color: '#e2eaff',
          backgroundColor: '#505050'
        },
        arrow: {
          color: '#505050'
        }
      }
    }
  }
};

export default theme;

import moment from 'moment';

const escalatedYears = [2030, 2029, 2028, 2027, 2026, 2025, 2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012];

const calculateEffectiveEscalation = (escalationRates, fromDate, toDate) => {
  if (!escalationRates) {
    return null;
  }

  // Dates needed for calculations
  const escalationBuyoutDate = moment(fromDate).startOf('day');
  const escalationBuyoutDateYear = moment(escalationBuyoutDate).get('year');
  const escalationToDate = moment(toDate).startOf('day');
  const escalationToDateYear = moment(escalationToDate).get('year');

  let effectiveEscaltion = 1;
  let i = escalationToDateYear;

  // Loop over all the years included in this escalation calculation and find the percentage for that year
  while (i >= escalationBuyoutDateYear) {
    const escalationRateForYear = parseFloat(escalationRates[i] || 0) / 100;
    let percentageForYear;

    // If the buyout date and estimate date are the same year, calculate the percent based on the difference between the dates in days
    if (i === escalationToDateYear && i === escalationBuyoutDateYear) {
      const daysBetween = moment(escalationToDate).diff(fromDate, 'days');
      percentageForYear = 1 + escalationRateForYear * (daysBetween / 365);
    }

    // If we're in the estimate year, calculate the percent based on how many days passed since the start of the year
    else if (i === escalationToDateYear) {
      const firstDayOfYear = moment(escalationToDate).startOf('year');
      const toDateYearTotalDays = moment(escalationToDate).diff(firstDayOfYear, 'days');
      percentageForYear = 1 + escalationRateForYear * (toDateYearTotalDays / 365);
    }

    // If we're in the buyout year, calculate the percent based on how many total days surpassed in the year
    else if (i === escalationBuyoutDateYear) {
      const lastDayOfYear = moment(escalationBuyoutDate).endOf('year');
      const fromDateYearTotalDays = moment(lastDayOfYear).diff(escalationBuyoutDate, 'days');
      percentageForYear = 1 + escalationRateForYear * (fromDateYearTotalDays / 365);
    }

    // All other years can use the full escalation rate since they are the full 365 days
    else {
      percentageForYear = 1 + escalationRateForYear;
    }

    effectiveEscaltion *= parseFloat(percentageForYear);
    i--;
  }

  const calculatedEscalationRate = Math.round((parseFloat(effectiveEscaltion) - 1) * 100);
  return calculatedEscalationRate;
};

export { calculateEffectiveEscalation, escalatedYears };

import { configureStore } from '@reduxjs/toolkit';

import estimateReducer from './slices/estimateSlice-old';
import compareReducer from './slices/compareSlice';
import { reducer as estimateCalculationsReducer } from '@/pages/estimate/calculations/_redux';

export const store = configureStore(
  {
    reducer: {
      estimate: estimateReducer,
      compare: compareReducer,
      estimateCalculations: estimateCalculationsReducer
    }
  },
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

import { v4 as uuid } from 'uuid';

import moment from 'moment';
import { designFields, initialEstimateConfig } from './constants';

import { defaultFilterConfig } from '@/helpers/constants';
import { INDIRECT_COSTS_CALCULATION_TYPES, INDIRECT_COSTS_FORMULA_TYPES } from '@/helpers/enums';

const safeDivide = (a, b) => {
  return b === 0 ? 0 : a / b;
};

// This converts some old data to new formats
// We should do this in the DB, but this is temporary
const convertOldConfigData = (oldConfig, isConsolidated) => {
  try {
    let newConfig = { ...oldConfig };

    // Ensuring old estimates have the new indirect costs attached to them
    if (!isConsolidated) {
      const newCostSetting = { ...newConfig.costSetting };
      // Very old estimates might not have indirect costs
      if (!newCostSetting.root.indirectCosts) {
        newCostSetting.root.indirectCosts = {
          [uuid()]: {
            label: 'Insurance',
            code: '99.0',
            is_default_indirect_fee: true,
            calculation_type: INDIRECT_COSTS_CALCULATION_TYPES.PERCENT,
            value: newCostSetting.root.insuranceOfHardCost || 0,
            formula_type: INDIRECT_COSTS_FORMULA_TYPES.PERCENT_OF_DIRECT_COST,
            appliesToCostCategories: null
          },
          [uuid()]: {
            label: 'GC Fee',
            code: '99.1',
            is_default_indirect_fee: true,
            calculation_type: INDIRECT_COSTS_CALCULATION_TYPES.PERCENT,
            value: newCostSetting.root.gcFeeOfHardCost || 0,
            formula_type: INDIRECT_COSTS_FORMULA_TYPES.PERCENT_OF_DIRECT_COST,
            appliesToCostCategories: null
          },
          [uuid()]: {
            label: 'Contingency',
            code: '99.2',
            is_default_indirect_fee: true,
            calculation_type: INDIRECT_COSTS_CALCULATION_TYPES.PERCENT,
            value: newCostSetting.root.contingencyOfHardCost || 0,
            formula_type: INDIRECT_COSTS_FORMULA_TYPES.PERCENT_OF_DIRECT_COST,
            appliesToCostCategories: null
          }
        };
      }
      // Newer estimates will have indirect costs, but in an array format so we convert to object with keys
      // We should eventually move this to the DB
      else if (Array.isArray(newCostSetting.root.indirectCosts)) {
        let newIndirectCosts = {};
        newCostSetting.root.indirectCosts.forEach((indirectCost) => {
          newIndirectCosts[uuid()] = { ...indirectCost };
          delete newIndirectCosts.uid;
        });
        newCostSetting.root.indirectCosts = newIndirectCosts;
      }

      // Adds a sort order since its an object now
      // Previously we just sorted by array order
      if (!newCostSetting.root.indirectCostSortOrder || !Array.isArray(newCostSetting.root.indirectCostSortOrder)) {
        newCostSetting.root.indirectCostSortOrder = Object.keys(newCostSetting.root.indirectCosts);
      }

      newConfig.costSetting = { ...newCostSetting };
    }

    // Ensuring old estimates have the new fieldOverridesEscalation attached to them
    if (!newConfig.costSetting.root.fieldOverridesEscalation) {
      newConfig.costSetting.root.fieldOverridesEscalation = {
        enabled: false,
        date: moment().format('YYYY-MM-DD'),
        calculatedEscalationRate: 0
      };
    }

    if (!isConsolidated) {
      // Converting comps to an object with keys instead of an array
      // We should eventually move this to the DB
      const newCompSetting = { ...newConfig.compSetting };
      if (newCompSetting.root.comps && Array.isArray(newCompSetting.root.comps)) {
        let newComps = {};
        newCompSetting.root.comps.forEach((comp) => {
          newComps[uuid()] = { ...comp };
        });
        newCompSetting.root.comps = newComps;
      }
      newConfig.compSetting = newCompSetting;
    }

    // Converting comps to an object with keys instead of an array
    if (!isConsolidated) {
      const newCompSetting = { ...newConfig.compSetting };
      if (newCompSetting.root.comps && Array.isArray(newCompSetting.root.comps)) {
        let newComps = {};
        newCompSetting.root.comps.forEach((comp) => {
          newComps[uuid()] = { ...comp };
        });
        newCompSetting.root.comps = newComps;
      }
      newConfig.compSetting = newCompSetting;
    }

    // Ensuring pro forma is always set
    if (!newConfig.proFormaSetting?.root) {
      newConfig.proFormaSetting = initialEstimateConfig.proFormaSetting;
    }

    // Older estimate include custom design fields in programming 'mixed use multifamily'
    // We need to pull custom design fields out of there
    if (!newConfig.programming) {
      newConfig.programming = initialEstimateConfig.programming;
    } else if (!newConfig.programming.customDesignFields) {
      const newProgrammingConfig = { ...newConfig.programming };
      if (newProgrammingConfig['Mixed-Use Multifamily']) {
        if (newProgrammingConfig['Mixed-Use Multifamily'].customDesignFields) {
          newProgrammingConfig.customDesignFields = { ...newProgrammingConfig['Mixed-Use Multifamily'].customDesignFields };
          delete newProgrammingConfig['Mixed-Use Multifamily'].customDesignFields;
        } else {
          newProgrammingConfig.customDesignFields = {};
        }
      } else {
        newConfig.programming = initialEstimateConfig.programming;
      }
      newConfig.programming = newProgrammingConfig;
    }

    if (!newConfig.programming.customDesignFields) {
      newConfig.programming.customDesignFields = {};
    }

    if (!newConfig.programming.estimateProgrammingConfig) {
      newConfig.programming.estimateProgrammingConfig = [];
    }

    // Ensure compare config is always set
    if (!newConfig.compareConfig?.root) {
      newConfig.compareConfig = initialEstimateConfig.compareConfig;
    }

    // Total cost UOM was a late addition to be saved
    // So we need to add it to any existing configs that don't have it
    if (!newConfig.totalCostUOM) {
      newConfig.totalCostUOM = null;
    }
    if (!newConfig.totalCostUOMType) {
      newConfig.totalCostUOMType = null;
    }

    return newConfig;
  } catch (err) {
    throw Error(err);
  }
};

// This converts the detailed estimates to new formats
const convertOldDetailedEstimates = (oldDetailedEstimates) => {
  let newDetailedEstimates = { ...oldDetailedEstimates };

  Object.keys(newDetailedEstimates).forEach((detailedEstimateUid) => {
    let newDetailedEstimate = { ...newDetailedEstimates[detailedEstimateUid] };

    // Previously detailed estimates were stored as an array of subfields
    // This converts it to an object for better performance
    if (Array.isArray(newDetailedEstimate.subFields)) {
      let newSubFields = {};

      newDetailedEstimate.subFields.forEach((subField) => {
        newSubFields[subField.uuid] = {
          ...subField
        };
      });

      newDetailedEstimate.subFields = newSubFields;
    }

    // Adds a sort order since its an object now
    // Previously we just sorted by array order
    if (!newDetailedEstimate.sortOrder || !Array.isArray(newDetailedEstimate.sortOrder)) {
      newDetailedEstimate.sortOrder = Object.keys(newDetailedEstimate.subFields);
    }

    newDetailedEstimates[detailedEstimateUid] = newDetailedEstimate;
  });

  return newDetailedEstimates;
};

// This converts old field calculations to new formats
const convertOldFieldCalculations = (oldFieldCalculations) => {
  let newFieldCalculations = { ...oldFieldCalculations };

  Object.keys(newFieldCalculations).forEach((fieldCalculationUid) => {
    if (!newFieldCalculations[fieldCalculationUid].selected_uom) {
      // Rename to selected_ to match the other calculations
      newFieldCalculations[fieldCalculationUid].selected_uom = newFieldCalculations[fieldCalculationUid].default_uom;
      newFieldCalculations[fieldCalculationUid].selected_uom_type = newFieldCalculations[fieldCalculationUid].default_uom_type;

      // delete old properties
      delete newFieldCalculations[fieldCalculationUid].default_uom;
      delete newFieldCalculations[fieldCalculationUid].default_uom_type;
    }
  });

  return newFieldCalculations;
};

const detailedEstimateSubFieldTemplate = (uom = '', uomType = 'standard', quantity = 0) => ({
  name: '',
  code: '',
  unitCost: 0,
  totalCost: 0,
  selectedUom: uom,
  selectedUomType: uomType,
  notes: '',
  quantity,
  isActive: true,
  uuid: uuid()
});

const detailedEstimateTemplate = (uom = '', uomType = 'standard', quantity = 0) => {
  const subField = detailedEstimateSubFieldTemplate(uom, uomType, quantity);

  return {
    detailEstActive: true,
    sortOrder: [subField.uuid],
    subFields: {
      [subField.uuid]: subField
    }
  };
};

const indirectCostTemplate = (code = '') => ({
  label: '',
  code,
  is_default_indirect_fee: false, // this field allows fees to be renamed/deleted
  calculation_type: INDIRECT_COSTS_CALCULATION_TYPES.PERCENT,
  value: 0,
  formula_type: INDIRECT_COSTS_FORMULA_TYPES.PERCENT_OF_DIRECT_COST,
  appliesToCostCategories: null
});

/* eslint-disable */
const getEstimateDesignValue = (ec, designFieldUid) => {
  let calculatedValue = 0;

  switch (designFieldUid) {
    // NRSF
    case '22539d6e2857419ea6e10927d74de3f5':
    case 'NRSF':
      let nrsf = 0;

      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          nrsf += getEstimateDesignValue({ estimateConfig: childEc.estimate_config, manualItems: childEc.manual_items }, designFields.NRSF);
        });

        calculatedValue = nrsf;
        break;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.studio || []) {
        nrsf += u.count * u.area;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.oneBedroom || []) {
        nrsf += u.count * u.area;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.twoBedroom || []) {
        nrsf += u.count * u.area;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.threeBedroom || []) {
        nrsf += u.count * u.area;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.fourBedroom || []) {
        nrsf += u.count * u.area;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.fiveBedroom || []) {
        nrsf += u.count * u.area;
      }

      calculatedValue = nrsf;
      break;

    // Residential Building Footprint SF
    case '8f452c6e4ed24cb39fd89357791735f1':
    case 'ResidentialBuildingFootprint':
      let rbfp = 0;

      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          rbfp += getEstimateDesignValue({ estimateConfig: childEc.estimate_config, manualItems: childEc.manual_items }, designFields.ResidentialBuildingFootprint);
        });
        calculatedValue = rbfp;
        break;
      }

      if (ec?.manualItems?.residentialBuildingFootprint) {
        calculatedValue = parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.woodBuilding?.[0]?.residentialBuildingFootprint ?? 0);
      } else {
        calculatedValue =
          parseFloat(getEstimateDesignValue(ec, designFields.WGSF)) /
          parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.woodBuilding?.[0]?.numWoodLevels ?? 1);
        calculatedValue = calculatedValue === Infinity ? 0 : calculatedValue;
      }
      // We need to return the value here to override the global rounding at the end of the Switch-Case
      return calculatedValue;

    // Garage Footprint SF
    case '2e135285c7b843269e6fdf8034ca0419':
    case 'GarageFootprint':
      let gbfp = 0;

      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          gbfp += getEstimateDesignValue({ estimateConfig: childEc.estimate_config, manualItems: childEc.manual_items }, designFields.GarageFootprint);
        });
        calculatedValue = gbfp;
        break;
      }

      if (ec?.manualItems?.garageFootprint) {
        calculatedValue = parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.garage?.[0]?.garageFootprint ?? 0);
      } else {
        calculatedValue =
          parseFloat(getEstimateDesignValue(ec, designFields.ParkingArea)) /
          parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.garage?.[0]?.levels ?? 1);
        calculatedValue = calculatedValue === Infinity ? 0 : calculatedValue;
      }
      // We need to return the value here to override the global rounding at the end of the Switch-Case
      return calculatedValue;

    case 'a862f8cea7424768bfd2d3bea98254c2':
    case 'NumWoodLevels':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let maxNumWoodLevels = 0;
        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          let childEstWoodLevels = getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.NumWoodLevels);
          maxNumWoodLevels = childEstWoodLevels > maxNumWoodLevels ? childEstWoodLevels : maxNumWoodLevels;
        });

        calculatedValue = maxNumWoodLevels;
        break;
      }

      calculatedValue = parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.woodBuilding?.[0]?.numWoodLevels ?? 1);
      break;

    case '5e5eae9a45c2429ca2be5810af5ea386':
    case 'NumConcreteLevels':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let maxConcreteLevels = 0;
        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          let childConcreteLevels = getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.NumConcreteLevels);
          maxConcreteLevels = childConcreteLevels > maxConcreteLevels ? childConcreteLevels : maxConcreteLevels;
        });

        calculatedValue = maxConcreteLevels;
        break;
      }

      calculatedValue = parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.woodBuilding?.[0]?.numConcreteLevels ?? 0);
      break;

    // WGSF
    case 'dc9922f630c5495ba08953479877845c':
      // WGSF = (NRSF / Efficiency) - Liner GSF

      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let sumWGSF = 0;
        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          sumWGSF += getEstimateDesignValue({ estimateConfig: childEc.estimate_config, manualItems: childEc.manual_items }, designFields.WGSF);
        });

        calculatedValue = sumWGSF;
        break;
      }

      if (ec?.manualItems?.WGSF) {
        calculatedValue = ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.woodBuilding?.[0]?.WGSF ?? 0;
      } else if (ec?.estimateConfig?.generalInformation?.root?.productType === 'high_rise') {
        calculatedValue = 0;
      } else {
        calculatedValue =
          getEstimateDesignValue(ec, designFields.NRSF) /
            (parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.woodBuilding?.[0]?.efficiency ?? 0) / 100) -
          parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.woodBuilding?.[0]?.linerGSF ?? 0);

        calculatedValue = calculatedValue;
        break;
      }
      break;

    case 'GLA':
      // This is only for High-rise
      if (ec?.manualItems?.GLA) {
        calculatedValue = ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.woodBuilding?.[0]?.GLA ?? 0;
      } else {
        // NRSF / Building Efficiency
        calculatedValue = parseFloat(getEstimateDesignValue(ec, designFields.NRSF)) / (parseFloat(getEstimateDesignValue(ec, designFields.BuildingEfficiency)) / 100);
        calculatedValue = calculatedValue;
      }
      break;

    case 'GBA':
      // This is only for High-rise
      // GLA + Parking Area
      calculatedValue = parseFloat(getEstimateDesignValue(ec, designFields.GLA)) + parseFloat(getEstimateDesignValue(ec, 'ParkingArea'));
      calculatedValue = calculatedValue;
      break;

    case 'BuildingEfficiency':
    case 'e9c776c1cb8c4fe4ae269ef6088db227':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let consolidatedNRSF = getEstimateDesignValue(ec, designFields.NRSF);
        let consolidatedWGSF = getEstimateDesignValue(ec, designFields.WGSF);

        calculatedValue = (consolidatedNRSF / consolidatedWGSF) * 100;
        break;
      }

      if (ec.manualItems.efficiency) {
        calculatedValue = parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.woodBuilding?.[0]?.efficiency || 0);
        calculatedValue = isNaN(calculatedValue) ? 0 : calculatedValue;
      } else {
        const nrsf = getEstimateDesignValue(ec, designFields.NRSF);
        const wgsf = getEstimateDesignValue(ec, designFields.WGSF);
        calculatedValue = safeDivide(nrsf, wgsf) * 100;
      }
      break;

    //	GSF (Liner Unit)
    case '0eec9102b16e47eaac1ab7cf0c99ae59':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let linerGSF = 0;
        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          linerGSF += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.LinerGSF);
        });

        calculatedValue = linerGSF;
        break;
      }

      calculatedValue = ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.woodBuilding?.[0]?.linerGSF;
      calculatedValue = isNaN(calculatedValue) ? 0 : calculatedValue;
      break;

    // WGSF + Liner GSF
    case 'c6e70f2f69444d508387f1cf135afb86':
    case 'WGSFPlusLinerGSF':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let consolidatedLinerGSF = getEstimateDesignValue(ec, designFields.LinerGSF);
        let consolidatedWGSF = getEstimateDesignValue(ec, designFields.WGSF);

        calculatedValue = consolidatedWGSF + consolidatedLinerGSF;
        break;
      }

      // if (ec?.estimateConfig?.generalInformation?.root?.productType === "high_rise") {
      //   calculatedValue = getEstimateDesignValue(ec, "GLA");
      // } else {
      calculatedValue =
        parseFloat(getEstimateDesignValue(ec, 'dc9922f630c5495ba08953479877845c') ?? 0) +
        parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.woodBuilding?.[0]?.linerGSF ?? 0);
      // }
      break;

    // Total GSF
    case 'abeabfb75f10436a9c44725897bb3e59':
    case 'TotalGSF':
      let consolidatedLinerGSF = getEstimateDesignValue(ec, designFields.LinerGSF);
      let consolidatedWGSF = getEstimateDesignValue(ec, designFields.WGSF);
      let parkingArea = getEstimateDesignValue(ec, designFields.ParkingArea);
      calculatedValue = consolidatedWGSF + consolidatedLinerGSF + parkingArea;

      break;
    // Concrete Structure GSF
    case '941e018ca40542338ef7cf37cfe51334':
    case 'ConcreteGSF':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let consolidatedCGSF = 0;

        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          consolidatedCGSF += getEstimateDesignValue({ estimateConfig: childEc.estimate_config, manualItems: childEc.manual_items }, designFields.ConcreteGSF);
        });

        calculatedValue = consolidatedCGSF;
        break;
      }

      if (ec?.estimateConfig?.generalInformation?.root?.productType === 'high_rise') {
        // calculatedValue = getEstimateDesignValue(ec, "GBA");
        calculatedValue = parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.garage?.[0]?.concreteGSF ?? 0);
      } else {
        if (ec?.manualItems?.concreteGSF) {
          calculatedValue = parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.garage?.[0]?.concreteGSF ?? 0);
          // console.log("concreteGSF:", calculatedValue);
        } else {
          // if product type = wrap: Concrete GSF = unit count x parking ratio * parking efficiency
          if (ec?.estimateConfig?.generalInformation?.root?.productType === 'wrap')
            calculatedValue =
              getEstimateDesignValue(ec, 'cfad0164f8854f36ad52d34ed29f01d9') * // Unit Count
                parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.garage?.[0]?.ratio ?? 0) *
                parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.garage?.[0]?.efficiency ?? 0) +
              getEstimateDesignValue(ec, '0eec9102b16e47eaac1ab7cf0c99ae59') +
              getEstimateDesignValue(ec, '8f452c6e4ed24cb39fd89357791735f1');
          // if product type = podium: Concrete GSF = unit count x parking ratio * parking efficiency / parking levels x (parking levels + 1)
          else if (ec?.estimateConfig?.generalInformation?.root?.productType === 'podium') {
            let unit_count = getEstimateDesignValue(ec, 'cfad0164f8854f36ad52d34ed29f01d9');
            let parking_ratio = parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.garage?.[0]?.ratio ?? 0);
            let parking_efficiency = parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.garage?.[0]?.efficiency ?? 0);
            let parking_levels = getEstimateDesignValue(ec, designFields.ParkingLevels);

            calculatedValue =
              ((unit_count * parking_ratio * parking_efficiency) / parking_levels) * (parking_levels + 1) + getEstimateDesignValue(ec, '0eec9102b16e47eaac1ab7cf0c99ae59');
          } else if (ec?.estimateConfig?.generalInformation?.root?.productType === 'slab_on_grade') {
            calculatedValue = getEstimateDesignValue(ec, '8f452c6e4ed24cb39fd89357791735f1');
          } else calculatedValue = 0;
        }
      }

      break;

    // Parking Area
    case '8f0d071974734d89b1d480a9b7f7cd23':
    case 'ParkingArea':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let consolidatedParkingArea = 0;

        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          consolidatedParkingArea += getEstimateDesignValue({ estimateConfig: childEc.estimate_config, manualItems: childEc.manual_items }, designFields.ParkingArea);
        });

        calculatedValue = consolidatedParkingArea;
        break;
      }

      if (ec?.manualItems?.parkingArea) {
        calculatedValue = parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.garage?.[0]?.parkingArea ?? 0);
      } else {
        calculatedValue =
          getEstimateDesignValue(ec, 'cfad0164f8854f36ad52d34ed29f01d9') * // Unit Count
            parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.garage?.[0]?.ratio ?? 0) *
            parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.garage?.[0]?.efficiency ?? 0) +
          getEstimateDesignValue(ec, '0eec9102b16e47eaac1ab7cf0c99ae59');
      }
      break;

    // Parking Levels
    case '01731bb699664decb1ccba1faa9ee0b5':
    case 'ParkingLevels':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let maxParkingLevels = 0;

        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          let childParkingLevels = getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.ParkingLevels);
          maxParkingLevels = childParkingLevels > maxParkingLevels ? childParkingLevels : maxParkingLevels;
        });

        calculatedValue = maxParkingLevels;
        break;
      }

      calculatedValue = parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.garage?.[0]?.levels ?? 0);
      break;

    // Parking Efficiency
    case 'f5cf9d08c409420fba46876ac9cb6e51':
    case 'ParkingEfficiency':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let totaNumberOfConcreteStalls = 0;

        let consolidatedParkingArea = getEstimateDesignValue(ec, designFields.ParkingArea);

        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          totaNumberOfConcreteStalls += getEstimateDesignValue({ estimateConfig: childEc.estimate_config, manualItems: childEc.manual_items }, designFields.ParkingStalls);
        });

        calculatedValue = consolidatedParkingArea / totaNumberOfConcreteStalls;
        break;
      }

      calculatedValue = ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.garage?.[0]?.efficiency;
      break;

    case '6c1cd092e7ee46049b1a16986daab0ea':
    case 'ParkingRatio':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let consolidatedParkingRatio = 0;
        let consolidatedParkingTotal = 0;

        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          let parkingRatio = getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.ParkingRatio);
          let unitCount = getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.UnitCount);
          consolidatedParkingTotal += parkingRatio * unitCount;
        });

        consolidatedParkingRatio = consolidatedParkingTotal / getEstimateDesignValue(ec, designFields.UnitCount);

        calculatedValue = consolidatedParkingRatio || 0;
        break;
      }
      calculatedValue = parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.garage?.[0]?.ratio ?? 0);
      break;

    case 'ParkingRatioOverall':
      // if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
      //   let consolidatedParkingRatio = 0;
      //   let consolidatedParkingTotal = 0;

      //   let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
      //     let parkingRatio = getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.ParkingRatio);
      //     let unitCount = getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.UnitCount);
      //     consolidatedParkingTotal += parkingRatio * unitCount;
      //   });

      //   consolidatedParkingRatio = consolidatedParkingTotal / getEstimateDesignValue(ec, designFields.UnitCount);

      //   calculatedValue = consolidatedParkingRatio || 0;
      //   break;
      // }
      // calculatedValue = parseFloat(ec?.estimateConfig?.programming?.["Mixed-Use Multifamily"]?.unitMix?.garage?.[0]?.ratio ?? 0);

      let totalStalls =
        getEstimateDesignValue(ec, designFields.ParkingStalls) +
        getEstimateDesignValue(ec, designFields.WoodGarageStandAloneStalls) +
        getEstimateDesignValue(ec, designFields.WoodGarageTuckUnderStalls) +
        getEstimateDesignValue(ec, designFields.SurfaceParkingStalls);
      calculatedValue = totalStalls / getEstimateDesignValue(ec, designFields.UnitCount);
      break;

    case 'ParkingStallsOverall':
      let consolidatedParkingStallsOverall = 0;

      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        // Consolidated
        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc, idx) => {
          consolidatedParkingStallsOverall += getEstimateDesignValue(
            { estimateConfig: childEc.estimate_config, manualItems: childEc.manual_items },
            designFields.ParkingStallsOverall
          );
        });

        calculatedValue = consolidatedParkingStallsOverall;
      } else {
        // Not Consolidated:
        let parkingStallsOverall =
          getEstimateDesignValue(ec, designFields.ParkingStalls) +
          getEstimateDesignValue(ec, designFields.WoodGarageStandAloneStalls) +
          getEstimateDesignValue(ec, designFields.WoodGarageTuckUnderStalls) +
          getEstimateDesignValue(ec, designFields.SurfaceParkingStalls);
        calculatedValue = parkingStallsOverall;
      }
      break;

    case 'be8a38681b404ed0af238f6e0ab0e3af':
    case 'ParkingStalls':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let consolidatedParkingStallsTotal = 0;

        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc, idx) => {
          let parkingStalls = getEstimateDesignValue({ estimateConfig: childEc.estimate_config, manualItems: childEc.manual_items }, designFields.ParkingStalls);

          consolidatedParkingStallsTotal += parkingStalls;
        });

        calculatedValue = consolidatedParkingStallsTotal || 0;
        break;
      }

      // If not Consolidated:
      // If manual:
      if (ec?.manualItems?.parkingStalls) {
        calculatedValue = parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.garage?.[0]?.parkingStalls ?? 0);
      } else {
        // If Automatic
        let parkingRatio = getEstimateDesignValue(ec, designFields.ParkingRatio);
        let unitCount = getEstimateDesignValue(ec, designFields.UnitCount);
        calculatedValue += parkingRatio * unitCount;
        break;
      }

      // calculatedValue = parseFloat(ec?.estimateConfig?.programming?.["Mixed-Use Multifamily"]?.unitMix?.garage?.[0]?.parkingStalls ?? 0);
      break;

    // Wood Garage SF Stand Alone
    // case "4b9f5aaa818544159eaa43770389335b":
    case 'c88f585b422a470a9701149ed678700f':
    case 'WoodGarageStandAlone':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let consolidatedWoodGarage = 0;

        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          consolidatedWoodGarage += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.WoodGarageStandAlone);
        });

        calculatedValue = consolidatedWoodGarage;
        break;
      }

      calculatedValue = parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.woodGarage?.[0]?.woodGarageStandAlone ?? 0);
      break;

    case '64730e8c2e154ec6a90b9a73a52570cc':
    case 'WoodGarageStandAloneStalls':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let consolidatedWoodGarageStalls = 0;

        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          consolidatedWoodGarageStalls += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.WoodGarageStandAloneStalls);
        });

        calculatedValue = consolidatedWoodGarageStalls;
        break;
      }

      calculatedValue = parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.woodGarage?.[0]?.woodGarageStandAloneStalls ?? 0);
      break;

    case '5ed9f6112eee49c0a27e4d08050c8048':
    case 'WoodGarageTuckUnderSF':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let consolidatedWoodGarageTuckUnderSF = 0;

        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          consolidatedWoodGarageTuckUnderSF += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.WoodGarageTuckUnderSF);
        });

        calculatedValue = consolidatedWoodGarageTuckUnderSF;
        break;
      }

      calculatedValue = parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.woodGarage?.[0]?.woodGarageTuckUnderSF ?? 0);
      break;

    case '2cee1ff74014401c9cf69adfe782cbae':
    case 'WoodGarageTuckUnderStalls':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let consolidatedWoodGarageTuckUnderStalls = 0;

        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          consolidatedWoodGarageTuckUnderStalls += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.WoodGarageTuckUnderStalls);
        });

        calculatedValue = consolidatedWoodGarageTuckUnderStalls;
        break;
      }

      calculatedValue = parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.woodGarage?.[0]?.woodGarageTuckUnderStalls ?? 0);
      break;

    case '7fc4756ebec84d04b85f4841f72f4d4a':
    case 'SurfaceParkingStalls':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let consolidatedSurfaceParkingStalls = 0;

        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          consolidatedSurfaceParkingStalls += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.SurfaceParkingStalls);
        });

        calculatedValue = consolidatedSurfaceParkingStalls;
        break;
      }

      calculatedValue = parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.surfaceParking?.[0]?.surfaceParkingStalls ?? 0);
      break;

    // Rec/Leasing SF (Total)
    case 'd561f238baec4e199c7cc7a9bc33c8f6':
    case 'RecLeasingSF':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let consolidatedRecLeasingSF = 0;

        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          consolidatedRecLeasingSF += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.RecLeasingSF);
        });

        calculatedValue = consolidatedRecLeasingSF;
        break;
      }
      calculatedValue = ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.recLeasing?.[0]?.area ?? 0;
      break;

    // Retail SF (Total)
    case '01b7e4f8e0844eb39cda1287b958f016':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let consolidatedRetailSF = 0;

        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          consolidatedRetailSF += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.RetailSF);
        });

        calculatedValue = consolidatedRetailSF;
        break;
      }
      calculatedValue = ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.retail?.[0]?.area ?? 0;
      break;

    // Common Area Open SF (On Grade)
    case '1b9ebaf01e64448db3be54af43f5f13d':
    case 'CommonAreaOnGradeSF':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let consolidatedCommonAreaOnGradeSF = 0;

        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          let childCAOGSF = getEstimateDesignValue({ estimateConfig: childEc.estimate_config, manualItems: childEc.manual_items }, designFields.CommonAreaOnGradeSF);
          consolidatedCommonAreaOnGradeSF += childCAOGSF;
        });
        calculatedValue = consolidatedCommonAreaOnGradeSF;
        break;
      }

      if (ec?.manualItems?.onGrade) {
        calculatedValue = parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.openCommonArea?.[0]?.onGrade ?? 0);
      } else {
        let lot_size_gross_sf = parseFloat(getEstimateDesignValue(ec, designFields.LotSizeGrossSF));
        let total_street_sf = parseFloat(getEstimateDesignValue(ec, designFields.StreetsTotalSF));
        let concrete_gsf = parseFloat(getEstimateDesignValue(ec, designFields.ConcreteGSF));
        let parking_area = parseFloat(getEstimateDesignValue(ec, designFields.ParkingArea));
        let wood_garage_stand_alone = parseFloat(getEstimateDesignValue(ec, designFields.WoodGarageStandAlone));
        let parking_levels = getEstimateDesignValue(ec, designFields.ParkingLevels);
        let wgsf = parseFloat(getEstimateDesignValue(ec, 'dc9922f630c5495ba08953479877845c'));
        let num_wood_levels = ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.woodBuilding?.[0]?.numWoodLevels ?? 0;
        let rec_leasing_sf = parseFloat(getEstimateDesignValue(ec, 'RecLeasingSF'));

        // If product type = Slab on Grade or wood podium:
        //  On Grade Common Area (SF) = Lot Size Gross (SF) - (Streets (Total SF)  - (WGSF / Number of Levels) )
        if (ec?.estimateConfig?.generalInformation?.root?.productType === 'slab_on_grade' || ec?.estimateConfig?.generalInformation?.root?.productType === 'wood_podium')
          calculatedValue = lot_size_gross_sf - total_street_sf - wood_garage_stand_alone - rec_leasing_sf - wgsf / num_wood_levels;
        else if (ec?.estimateConfig?.generalInformation?.root?.productType === 'wrap')
          calculatedValue = lot_size_gross_sf - total_street_sf - wgsf / num_wood_levels - concrete_gsf / parking_levels;
        else if (ec?.estimateConfig?.generalInformation?.root?.productType === 'podium')
          calculatedValue = lot_size_gross_sf - total_street_sf - concrete_gsf / (parking_levels + 1);
        else if (ec?.estimateConfig?.generalInformation?.root?.productType === 'high_rise') calculatedValue = lot_size_gross_sf - total_street_sf - parking_area / parking_levels;
      }
      calculatedValue = calculatedValue || 0;
      break;

    // Common Area Open Acre (On Grade)
    case 'CommonAreaOnGradeAcre':
      calculatedValue = parseFloat(getEstimateDesignValue(ec, designFields.CommonAreaOnGradeSF)) / 43560;
      break;

    // Common Area Open SF (On Structure)
    case '7b0d37e33ea34475b374f939666b7486':
    case 'CommonAreaOnStructureSF':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let consolidatedCommonAreaOnStrucSF = 0;

        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          let childCAOGSF = getEstimateDesignValue({ estimateConfig: childEc.estimate_config, manualItems: childEc.manual_items }, designFields.CommonAreaOnStructureSF);
          consolidatedCommonAreaOnStrucSF += childCAOGSF;
        });
        calculatedValue = consolidatedCommonAreaOnStrucSF;
        break;
      }

      let concrete_gsf = parseFloat(getEstimateDesignValue(ec, designFields.ConcreteGSF));
      let parking_levels = getEstimateDesignValue(ec, designFields.ParkingLevels);
      let wgsf_plus_liner_gsf = parseFloat(getEstimateDesignValue(ec, designFields.WGSFPlusLinerGSF));
      let num_wood_levels = getEstimateDesignValue(ec, designFields.NumWoodLevels);
      let num_concrete_levels = getEstimateDesignValue(ec, designFields.NumConcreteLevels);
      let gla = parseFloat(getEstimateDesignValue(ec, designFields.GLA));

      if (ec?.manualItems?.onStructure) {
        calculatedValue = parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.openCommonArea?.[0]?.onStructure ?? 0);
      } else {
        if (
          ec?.estimateConfig?.generalInformation?.root?.productType === 'slab_on_grade' ||
          ec?.estimateConfig?.generalInformation?.root?.productType === 'wood_podium' ||
          ec?.estimateConfig?.generalInformation?.root?.productType === 'wrap'
        )
          calculatedValue = 0;
        else if (ec?.estimateConfig?.generalInformation?.root?.productType === 'podium' || ec?.estimateConfig?.generalInformation?.root?.productType === 'high_rise') {
          calculatedValue = concrete_gsf / (parking_levels + 1) - wgsf_plus_liner_gsf / (num_concrete_levels + parseInt(num_wood_levels));
        }
      }
      calculatedValue = calculatedValue || 0;
      break;

    // Common Area Open Acre (On Structure)
    case 'CommonAreaOnStructureAcre':
      calculatedValue = parseFloat(getEstimateDesignValue(ec, designFields.CommonAreaOnStructureSF)) / 43560;
      break;

    // Common Area Open Acre (Total)
    case '8c71a13beea24146a79323e498544572':
    case 'CommonAreaOpenAcreTotal':
      calculatedValue = parseFloat(getEstimateDesignValue(ec, designFields.CommonAreaOpenSFTotal)) / 43560;
      break;

    // Common Area Open SF (Total)
    case 'e29af06f5cd4473a9c1b702d094a4bcf':
    case 'CommonAreaOpenSFTotal':
      calculatedValue = parseFloat(getEstimateDesignValue(ec, designFields.CommonAreaOnStructureSF)) + parseFloat(getEstimateDesignValue(ec, designFields.CommonAreaOnGradeSF));
      break;

    case 'CommonAreaPerLotSize':
    case '9a7605dbd0cb4331be7afe36707d8a17':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let consolidatedLotSize = getEstimateDesignValue(ec, designFields.LotSizeGrossSF);
        let consolidatedCommonAreaOnGrade = getEstimateDesignValue(ec, designFields.CommonAreaOnGradeSF);

        calculatedValue = 100 * (consolidatedCommonAreaOnGrade / consolidatedLotSize);
        break;
      }

      calculatedValue = (100 * parseFloat(getEstimateDesignValue(ec, '8c71a13beea24146a79323e498544572'))) / getEstimateDesignValue(ec, designFields.LotSizeGrossAcre) || 0;
      calculatedValue = isNaN(calculatedValue) ? 0 : calculatedValue;
      break;

    // Lot Size (Gross SF)
    case '9a32223adbe14a158f358635a412080d':
    case 'LotSizeGrossSF':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        if (ec?.manualItems?.lotSize) {
          calculatedValue = parseFloat(ec?.estimateConfig?.generalInformation?.root?.lotSize || 0);
          calculatedValue = calculatedValue * (getEstimateDesignValue(ec, designFields.LotSizeUnit) === 'Acre' ? 43560 : 1);
          break;
        } else {
          let ConsolidatedLotSize = 0;

          let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc, idx) => {
            let childLotSize = getEstimateDesignValue(
              { estimateConfig: childEc.estimate_config },
              getEstimateDesignValue(ec, designFields.LotSizeUnit) === 'Acre' ? designFields.LotSizeGrossAcre : designFields.LotSizeGrossSF
            );
            ConsolidatedLotSize += childLotSize;
          });
          calculatedValue = ConsolidatedLotSize * (getEstimateDesignValue(ec, designFields.LotSizeUnit) === 'Acre' ? 43560 : 1);
        }
        break;
      }

      calculatedValue = parseFloat((ec?.estimateConfig?.generalInformation?.root?.lotSize || 0) * (getEstimateDesignValue(ec, designFields.LotSizeUnit) === 'Acre' ? 43560 : 1));
      break;

    // Lot Size (Net Acre)
    case 'e642f31ef59a48e9a2aea8d78472867c':
      calculatedValue = getEstimateDesignValue(ec, designFields.LotSizeGrossAcre) - getEstimateDesignValue(ec, designFields.StreetsTotalSF) / 43560;
      return calculatedValue;
      break;

    //	Lot Size (Gross Acre)
    case '99705094935c42c29917436a20f62bbd':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        if (ec?.manualItems?.lotSize) {
          calculatedValue = parseFloat(ec?.estimateConfig?.generalInformation?.root?.lotSize || 0);
          calculatedValue = calculatedValue * (getEstimateDesignValue(ec, designFields.LotSizeUnit) === 'Acre' ? 1 : 1 / 43560);
          break;
        } else {
          let ConsolidatedLotSize = 0;

          let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc, idx) => {
            let childLotSize = getEstimateDesignValue(
              { estimateConfig: childEc.estimate_config },
              getEstimateDesignValue(ec, designFields.LotSizeUnit) === 'Acre' ? designFields.LotSizeGrossAcre : designFields.LotSizeGrossSF
            );
            ConsolidatedLotSize += childLotSize;
          });
          calculatedValue = ConsolidatedLotSize * (getEstimateDesignValue(ec, designFields.LotSizeUnit) === 'Acre' ? 1 : 1 / 43560);
          break;
        }
      }

      calculatedValue = parseFloat(
        (ec?.estimateConfig?.generalInformation?.root?.lotSize || 0) * (getEstimateDesignValue(ec, designFields.LotSizeUnit) === 'Acre' ? 1 : 1 / 43560)
      );

      return calculatedValue;
      break;

    //	Lot Size Unit
    case 'LotSizeUnit':
      calculatedValue = ec?.estimateConfig?.generalInformation?.root?.lotSizeUnit || 'Acre';
      return calculatedValue;
      break;

    // Lot Size (Net SF)
    case '4c7cd8cc001842b78922d04d1ec851d4':
      calculatedValue = getEstimateDesignValue(ec, designFields.LotSizeGrossSF) - getEstimateDesignValue(ec, designFields.StreetsTotalSF);
      break;

    // Pool / Spa SF
    case '858e6c857f7843168fa9d160fff62598':
    case 'PoolSpaSF':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let consolidatedPoolSpa = 0;

        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          consolidatedPoolSpa += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.PoolSpaSF);
        });
        calculatedValue = consolidatedPoolSpa;
        break;
      }

      calculatedValue = parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.openCommonArea?.[0]?.poolSpaSF ?? 0);
      break;

    // Streets (Total SF)
    case 'b644afd98a254a1982ab0c363a7b677f':
    case 'StreetsTotalSF':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let consolidatedTotalStreets = 0;

        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          consolidatedTotalStreets += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.StreetsTotalSF);
        });
        calculatedValue = consolidatedTotalStreets;
        break;
      }

      calculatedValue = parseFloat(ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.openCommonArea?.[0]?.streetsTotalSF ?? 0);
      break;

    // Bedroom
    case 'b27db6f1c6a54758b671bb2d39b902c6':
    case 'Bedrooms':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let consolidateBedroomsCount = 0;

        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          consolidateBedroomsCount += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.Bedrooms);
        });
        calculatedValue = consolidateBedroomsCount;
        break;
      }

      let bedrooms = 0;
      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.studio || []) {
        bedrooms += u.count;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.oneBedroom || []) {
        bedrooms += u.count;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.twoBedroom || []) {
        bedrooms += u.count * 2;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.threeBedroom || []) {
        bedrooms += u.count * 3;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.fourBedroom || []) {
        bedrooms += u.count * 4;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.fiveBedroom || []) {
        bedrooms += u.count * 5;
      }

      calculatedValue = bedrooms;
      break;

    // Bathroom (Full)
    case '14e5d4f915c546fa9ce9ad91a764c8ff':
    case 'FullBathrooms':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let consolidateFullBathCount = 0;

        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          consolidateFullBathCount += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.FullBathrooms);
        });
        calculatedValue = consolidateFullBathCount;
        break;
      }

      let fullBathroom = 0;
      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.studio || []) {
        fullBathroom += u.count * u.bathrooms;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.oneBedroom || []) {
        fullBathroom += u.count * u.bathrooms;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.twoBedroom || []) {
        fullBathroom += u.count * u.bathrooms;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.threeBedroom || []) {
        fullBathroom += u.count * u.bathrooms;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.fourBedroom || []) {
        fullBathroom += u.count * u.bathrooms;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.fiveBedroom || []) {
        fullBathroom += u.count * u.bathrooms;
      }
      calculatedValue = fullBathroom;
      break;

    // Bathroom (Half)
    case '42ec1048e4cd430999e3687e191bf98c':
    case 'HalfBathrooms':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let consolidateHalfBathCount = 0;

        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          consolidateHalfBathCount += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.HalfBathrooms);
        });
        calculatedValue = consolidateHalfBathCount;
        break;
      }

      let halfBathroom = 0;
      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.studio || []) {
        halfBathroom += u.count * (u.bathrooms - u.bathrooms === 0 ? 0 : 1);
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.oneBedroom || []) {
        halfBathroom += u.count * (u.bathrooms - u.bathrooms === 0 ? 0 : 1);
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.twoBedroom || []) {
        halfBathroom += u.count * (u.bathrooms - u.bathrooms === 0 ? 0 : 1);
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.threeBedroom || []) {
        halfBathroom += u.count * (u.bathrooms - u.bathrooms === 0 ? 0 : 1);
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.fourBedroom || []) {
        halfBathroom += u.count * (u.bathrooms - u.bathrooms === 0 ? 0 : 1);
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.fiveBedroom || []) {
        halfBathroom += u.count * (u.bathrooms - u.bathrooms === 0 ? 0 : 1);
      }
      calculatedValue = halfBathroom;
      break;

    // Bathroom (Total)
    case 'f254dffd142740718c05982c5cfab85a':
    case 'TotalBathrooms':
      // Bathroom (Total) = total number of bathrooms in all units
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let consolidateTotalBathroomsCount = 0;

        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          consolidateTotalBathroomsCount += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.TotalBathrooms);
        });
        calculatedValue = consolidateTotalBathroomsCount;
        break;
      }

      calculatedValue = getEstimateDesignValue(ec, designFields.FullBathrooms) + getEstimateDesignValue(ec, designFields.HalfBathrooms);
      break;

    // Unit Count
    case 'cfad0164f8854f36ad52d34ed29f01d9':
    case 'Unit':
      let unit = 0;

      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          unit += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.UnitCount);
        });

        calculatedValue = unit;
        break;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.studio || []) {
        unit += u.count;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.oneBedroom || []) {
        unit += u.count;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.twoBedroom || []) {
        unit += u.count;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.threeBedroom || []) {
        unit += u.count;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.fourBedroom || []) {
        unit += u.count;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.fiveBedroom || []) {
        unit += u.count;
      }
      calculatedValue = unit;
      break;

    case 'StudioCount':
    case 'e5416d6979714e2cb70bbb65f5701a2a':
      let studioCount = 0;

      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          studioCount += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.StudioCount);
        });

        calculatedValue = studioCount;
        break;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.studio || []) {
        studioCount += u.count;
      }

      calculatedValue = isNaN(studioCount) ? 0 : studioCount;
      break;

    case 'StudioPercent':
    case 'b2215789a3844f18aee9c2af89197046':
      calculatedValue = 100 * (getEstimateDesignValue(ec, designFields.StudioCount) / getEstimateDesignValue(ec, designFields.UnitCount));
      calculatedValue = isNaN(calculatedValue) ? 0 : calculatedValue;
      break;
    case 'OneBedroomCount':
    case '332aecb41fe24e2a9fdbe1f7188f131d':
      let oneBedCount = 0;
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          oneBedCount += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.OneBedroomCount);
        });

        calculatedValue = oneBedCount;
        break;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.oneBedroom || []) {
        oneBedCount += u.count;
      }

      calculatedValue = isNaN(oneBedCount) ? 0 : oneBedCount;
      break;

    case 'OneBedroomPercent':
    case 'b2fab0d3351349ad9986c85419ec76cf':
      calculatedValue = 100 * (getEstimateDesignValue(ec, designFields.OneBedroomCount) / getEstimateDesignValue(ec, designFields.UnitCount));
      calculatedValue = isNaN(calculatedValue) ? 0 : calculatedValue;
      break;

    case 'TwoBedroomCount':
    case '8a6d8365738b4efc9340e5b182ab4036':
      let twoBedCount = 0;
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          twoBedCount += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.TwoBedroomCount);
        });

        calculatedValue = twoBedCount;
        break;
      }
      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.twoBedroom || []) {
        twoBedCount += u.count;
      }

      calculatedValue = isNaN(twoBedCount) ? 0 : twoBedCount;
      break;

    case 'TwoBedroomPercent':
    case '78078ac87c144037abcadb50406d5019':
      calculatedValue = 100 * (getEstimateDesignValue(ec, designFields.TwoBedroomCount) / getEstimateDesignValue(ec, designFields.UnitCount));
      calculatedValue = isNaN(calculatedValue) ? 0 : calculatedValue;
      break;

    case 'ThreeBedroomCount':
    case '2ffe4f876e9942089982ab785cc72eb1':
      let threeBedCount = 0;
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          threeBedCount += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.ThreeBedroomCount);
        });

        calculatedValue = threeBedCount;
        break;
      }
      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.threeBedroom || []) {
        threeBedCount += u.count;
      }

      calculatedValue = isNaN(threeBedCount) ? 0 : threeBedCount;
      break;

    case 'ThreeBedroomPercent':
    case '8ca30162fbc642bd947e6a3b2d087f53':
      calculatedValue = 100 * (getEstimateDesignValue(ec, designFields.ThreeBedroomCount) / getEstimateDesignValue(ec, designFields.UnitCount));
      calculatedValue = isNaN(calculatedValue) ? 0 : calculatedValue;
      calculatedValue = calculatedValue;
      break;

    case 'FourBedroomCount':
    case '3f6be64b9aab4f2c9c3cb0b7759d24b7':
      let fourBedCount = 0;
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          fourBedCount += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.FourBedroomCount);
        });

        calculatedValue = fourBedCount;
        break;
      }
      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.fourBedroom || []) {
        fourBedCount += u.count;
      }

      calculatedValue = isNaN(fourBedCount) ? 0 : fourBedCount;
      break;

    case 'FourBedroomPercent':
    case '1aba41d496ff462e8861ac0eb049ed65':
      calculatedValue = 100 * (getEstimateDesignValue(ec, designFields.FourBedroomCount) / getEstimateDesignValue(ec, designFields.UnitCount));
      calculatedValue = isNaN(calculatedValue) ? 0 : calculatedValue;
      calculatedValue = calculatedValue;
      break;

    case 'FiveBedroomCount':
    case 'f183777429b94af79bc331d28a20370b':
      let fiveBedCount = 0;
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          fiveBedCount += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.FiveBedroomCount);
        });

        calculatedValue = fiveBedCount;
        break;
      }
      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.fiveBedroom || []) {
        fiveBedCount += u.count;
      }

      calculatedValue = isNaN(fiveBedCount) ? 0 : fiveBedCount;
      break;

    case 'FiveBedroomPercent':
    case '418c38e05e234111b125d9393b446f4e':
      calculatedValue = 100 * (getEstimateDesignValue(ec, designFields.FiveBedroomCount) / getEstimateDesignValue(ec, designFields.UnitCount));
      calculatedValue = isNaN(calculatedValue) ? 0 : calculatedValue;
      calculatedValue = calculatedValue;
      break;

    case 'AvgUnitSize':
    case '295384e3b3c04b5bb221f8ea80522d0a':
      calculatedValue = getEstimateDesignValue(ec, designFields.NRSF) / getEstimateDesignValue(ec, designFields.UnitCount);
      calculatedValue = isNaN(calculatedValue) ? 0 : calculatedValue;
      break;

    // Waste Outlet (Total)
    case '06929a607473493abfe80aa68784c289':
    case 'WasteOutlets':
      let wasteOutlets = 0;

      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          wasteOutlets += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.WasteOutlets);
        });

        calculatedValue = wasteOutlets;
        break;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.studio || []) {
        wasteOutlets += u.count * u.wasteoutlets ?? 5;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.oneBedroom || []) {
        wasteOutlets += u.count * u.wasteoutlets ?? 5;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.twoBedroom || []) {
        wasteOutlets += u.count * u.wasteoutlets ?? 8;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.threeBedroom || []) {
        wasteOutlets += u.count * u.wasteoutlets ?? 8;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.fourBedroom || []) {
        wasteOutlets += u.count * u.wasteoutlets ?? 11;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.fiveBedroom || []) {
        wasteOutlets += u.count * u.wasteoutlets ?? 11;
      }

      calculatedValue = wasteOutlets;
      break;

    // Windowroom
    case '4120274c82e749f69f8e5d07806203bc':
    case 'WindowRooms':
      let windowRoom = 0;

      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          windowRoom += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.WindowRooms);
        });

        calculatedValue = windowRoom;
        break;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.studio || []) {
        windowRoom += u.count;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.oneBedroom || []) {
        windowRoom += u.count * 2;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.twoBedroom || []) {
        windowRoom += u.count * 3;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.threeBedroom || []) {
        windowRoom += u.count * 4;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.fourBedroom || []) {
        windowRoom += u.count * 5;
      }

      for (let u of ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.fiveBedroom || []) {
        windowRoom += u.count * 6;
      }

      calculatedValue = windowRoom;
      break;

    case 'ConstructionType':
      let constructionTypes = [];

      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          const constructionType = getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.ConstructionType);
          if (constructionType) {
            constructionTypes.push(constructionType);
          }
        });

        if (constructionTypes.every((ct) => ct === constructionTypes[0])) return constructionTypes[0];
        else return 'Various';
      }

      return ec?.estimateConfig?.generalInformation?.root?.constructionType;

    case 'cacbd39ede684ede86e6b44dc230dba1':
    case 'ProductType':
      let productTypes = [];

      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          const productType = getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.ProductType);
          if (productType) {
            productTypes.push(productType);
          }
        });
        if (productTypes.every((ct) => ct === productTypes[0])) return productTypes[0];
        else return 'various';
      }

      return ec?.estimateConfig?.generalInformation?.root?.productType;

    // Construction Duration (Building)
    case 'd8b2d78a40024fad80060823e7d1fc28':
    case 'ConstructionDuration':
      let constructionDuration = 0;

      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        if (ec?.manualItems?.constructionDuration) {
          calculatedValue = ec?.estimateConfig?.generalInformation?.root?.constructionDuration ?? 0;
          break;
        } else {
          let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
            constructionDuration += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.ConstructionDuration);
          });

          calculatedValue = constructionDuration;
          break;
        }
      }

      calculatedValue = ec?.estimateConfig?.generalInformation?.root?.constructionDuration ?? 0;
      break;

    case 'RetailGrossIncomePerYear':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let retailGrossIncomePerYear = 0;
        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          retailGrossIncomePerYear += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.RetailGrossIncomePerYear);
        });

        calculatedValue = retailGrossIncomePerYear;
        break;
      }

      calculatedValue = getEstimateDesignValue(ec, designFields.RetailRent) * getEstimateDesignValue(ec, '01b7e4f8e0844eb39cda1287b958f016') * 12;
      break;

    case 'RetailVacancyLossPerYear':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let retailVacancyLossPerYear = 0;
        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          retailVacancyLossPerYear += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.RetailVacancyLossPerYear);
        });

        calculatedValue = retailVacancyLossPerYear;
        break;
      }

      calculatedValue = getEstimateDesignValue(ec, 'RetailGrossIncomePerYear') * (getEstimateDesignValue(ec, 'RetailVacancyRate') / 100);
      break;

    case 'RetailRent':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let retailRent = 0;
        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          retailRent += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.RetailRent);
        });

        calculatedValue = retailRent;
        break;
      }

      calculatedValue = ec?.estimateConfig?.proFormaSetting?.root?.retailRent ?? 0;
      break;
    case 'RetailVacancyRate':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let retailVacancyRate = 0;
        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          retailVacancyRate += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.RetailVacancyRate);
        });

        calculatedValue = retailVacancyRate;
        break;
      }

      calculatedValue = parseFloat(ec?.estimateConfig?.proFormaSetting?.root?.retailVacancyRate ?? 0);
      break;

    case 'AptGrossIncomePerYear':
      let aptGrossIncomePerYear = 0;

      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          aptGrossIncomePerYear += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.AptGrossIncomePerYear);
        });

        calculatedValue = aptGrossIncomePerYear;
        break;
      }

      (ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.studio || []).forEach((unit, idx) => {
        aptGrossIncomePerYear += unit.count * unit.area * ec?.estimateConfig?.proFormaSetting?.root?.studio?.[idx] || 0;
      });

      (ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.oneBedroom || []).forEach((unit, idx) => {
        aptGrossIncomePerYear += unit.count * unit.area * ec?.estimateConfig?.proFormaSetting?.root?.oneBedroom?.[idx] || 0;
      });

      (ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.twoBedroom || []).forEach((unit, idx) => {
        aptGrossIncomePerYear += unit.count * unit.area * ec?.estimateConfig?.proFormaSetting?.root?.twoBedroom?.[idx] || 0;
      });

      (ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.threeBedroom || []).forEach((unit, idx) => {
        aptGrossIncomePerYear += unit.count * unit.area * ec?.estimateConfig?.proFormaSetting?.root?.threeBedroom?.[idx] || 0;
      });

      (ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.fourBedroom || []).forEach((unit, idx) => {
        aptGrossIncomePerYear += unit.count * unit.area * ec?.estimateConfig?.proFormaSetting?.root?.fourBedroom?.[idx] || 0;
      });

      (ec?.estimateConfig?.programming?.['Mixed-Use Multifamily']?.unitMix?.fiveBedroom || []).forEach((unit, idx) => {
        aptGrossIncomePerYear += unit.count * unit.area * ec?.estimateConfig?.proFormaSetting?.root?.fiveBedroom?.[idx] || 0;
      });

      calculatedValue = aptGrossIncomePerYear * 12;

      break;
    case 'AptVacancyLossPerYear':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let retailVacancyLossPerYear = 0;
        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          retailVacancyLossPerYear += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.AptVacancyLossPerYear);
        });

        calculatedValue = retailVacancyLossPerYear;
        break;
      }

      calculatedValue = getEstimateDesignValue(ec, 'AptGrossIncomePerYear') * (getEstimateDesignValue(ec, 'AptVacancyRate') / 100);
      break;

    case 'AptVacancyRate':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let aptVacancyRate = 0;
        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          aptVacancyRate += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.AptVacancyRate);
        });

        calculatedValue = aptVacancyRate;
        break;
      }

      calculatedValue = parseFloat(ec?.estimateConfig?.proFormaSetting?.root?.aptVacancyRate ?? 0);
      break;

    case 'EffectiveGrossIncome':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let effectiveGrossIncome = 0;
        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          effectiveGrossIncome += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.EffectiveGrossIncome);
        });

        calculatedValue = effectiveGrossIncome;
        break;
      }

      calculatedValue =
        getEstimateDesignValue(ec, 'AptGrossIncomePerYear') +
        getEstimateDesignValue(ec, 'RetailGrossIncomePerYear') -
        getEstimateDesignValue(ec, 'AptVacancyLossPerYear') -
        getEstimateDesignValue(ec, 'RetailVacancyLossPerYear');
      break;

    case 'LandAcqCost':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let landAcqCost = 0;

        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          landAcqCost += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.LandAcqCost);
        });

        calculatedValue = landAcqCost;
        break;
      }

      calculatedValue = ec?.estimateConfig?.proFormaSetting?.root?.landAcqCost ?? 0;
      break;

    case 'OperatingExpenses':
      if (ec.isConsolidated && ec.consolidatedRecordsConfigs) {
        let operatingExpenses = 0;
        let _ = Object.values(ec.consolidatedRecordsConfigs)?.map((childEc) => {
          operatingExpenses += getEstimateDesignValue({ estimateConfig: childEc.estimate_config }, designFields.OperatingExpenses);
        });

        calculatedValue = operatingExpenses;
        break;
      }
      calculatedValue = getEstimateDesignValue(ec, designFields.EffectiveGrossIncome) * (parseFloat(ec?.estimateConfig?.proFormaSetting?.root?.operatingExpenses ?? 0) / 100);
      break;

    default:
      calculatedValue = 0;
  }

  if (!calculatedValue || isNaN(calculatedValue) || calculatedValue === Infinity || calculatedValue === -Infinity) {
    return 0;
  }

  return Math.round(calculatedValue * 100) / 100;
};
/* eslint-enable */

const getDivisionDefaultUom = (dUid) => {
  const divisions_default_uom = {
    '088236d979c9457785428f1286c761bf': 'd8b2d78a40024fad80060823e7d1fc28',
    e7c5aa724ae94b1f9d301edad82b6674: '99705094935c42c29917436a20f62bbd',
    '471feee08b4b4360a0383ca2da2b429f': '941e018ca40542338ef7cf37cfe51334',
    '394090abba4942e38b807c70b78476d9': '99705094935c42c29917436a20f62bbd',
    fedccebc4824404282717302dbd9fe84: 'cfad0164f8854f36ad52d34ed29f01d9',
    afa944eb7b404d9c995574c52a9a43b9: 'c6e70f2f69444d508387f1cf135afb86',
    '3d43a20c47304411a665d1d0d047a34c': 'c6e70f2f69444d508387f1cf135afb86',
    '82d74a3e0bed462fba66e3c0d08e2e20': 'b27db6f1c6a54758b671bb2d39b902c6',
    '708226e167f14ff0b155fa9b44de1777': 'c6e70f2f69444d508387f1cf135afb86',
    ec5c36f7730b478e81850d46f60c1620: 'cfad0164f8854f36ad52d34ed29f01d9',
    f0f82f5960194f169a51ef03d867ce0e: 'c6e70f2f69444d508387f1cf135afb86',
    '9787f88818c143a1bbb12ba770f73d79': 'e29af06f5cd4473a9c1b702d094a4bcf',
    '028c1dec0e3044d9ae0c62136ce11d3c': 'cfad0164f8854f36ad52d34ed29f01d9',
    '22f83d0f7fad4cf49e136cbba687ec40': 'cfad0164f8854f36ad52d34ed29f01d9',
    '2fa1acb919544c12a6b6c561d870d5cc': '06929a607473493abfe80aa68784c289',
    '87737e41cb9c4f4e8f5f61881b13987f': 'cfad0164f8854f36ad52d34ed29f01d9'
  };

  return divisions_default_uom[dUid];
};

const getBucketQuantity = (bucketUid, estimate) => {
  try {
    const bucketCalculation = estimate.bucketCalculations[bucketUid];
    const uomType = bucketCalculation.selected_uom_type;
    const uom = bucketCalculation.selected_uom;
    let quantity = 0;

    switch (uomType) {
      case 'custom_design_field':
        quantity = parseFloat(estimate.config.data.programming.customDesignFields[uom]?.value || 0);
        break;
      case 'custom_programming_field':
      case 'standard':
      default:
        quantity = parseFloat(estimate.estimateDesignValues[uom] || 0);
        break;
    }

    return isNaN(quantity) ? 0 : quantity;
  } catch (err) {
    throw Error(err);
  }
};

const getFieldQuantity = (fieldUid, estimate) => {
  try {
    const fieldCalculation = estimate.fieldCalculations[fieldUid];
    const uomType = fieldCalculation.selected_uom_type;
    const uom = fieldCalculation.selected_uom;
    let quantity = 0;

    switch (uomType) {
      case 'custom_value':
        quantity = parseFloat(fieldCalculation.custom_uom_value || 0);
        break;
      case 'custom_design_field':
        quantity = parseFloat(estimate.config.data.programming.customDesignFields[uom]?.value || 0);
        break;
      case 'custom_programming_field':
      case 'standard':
      default:
        quantity = parseFloat(estimate.estimateDesignValues[uom] || 0);
        break;
    }

    return isNaN(quantity) ? 0 : quantity;
  } catch (err) {
    throw Error(err);
  }
};

const getFieldUnitCost = (fieldUid, estimate) => {
  try {
    const fieldCalculation = estimate.fieldCalculations[fieldUid];
    const field = estimate.fields.data[fieldUid];

    const compSetting = estimate.config.data.compSetting.root.comps;
    const selectedRecCostFields = estimate.selectedRecCostFields[fieldUid];
    const bucketCalculations = estimate.bucketCalculations[field.meta.et_field_bucket];

    const activeCompUids = Object.keys(compSetting).filter((compUid) => {
      const comp = compSetting[compUid];
      const cr_field_active = selectedRecCostFields?.[comp.ruid]?.cr_field_active ?? true;
      const cr_comp_enabled = bucketCalculations?.comps?.[comp.ruid]?.cr_comp_enabled ?? true;
      return cr_field_active && cr_comp_enabled;
    });

    if (activeCompUids.length === 0) {
      return 0;
    }

    let compTotals = 0;
    let validCompsLength = 0;
    activeCompUids.forEach((compUid) => {
      const compUnitCost = getCompFieldUnitCost(compUid, fieldUid, estimate);

      if (isFinite(compUnitCost) && !isNaN(compUnitCost)) {
        compTotals += compUnitCost;
        if (compUnitCost > 0) {
          validCompsLength += 1;
        }
      }
    });

    let unitCost = safeDivide(compTotals, validCompsLength);
    let adjustedUnitCost = unitCost;
    const percentOfProject = parseFloat(fieldCalculation.adjustments?.percentOfProject || 100);
    const premiumPercentage = parseFloat(fieldCalculation.adjustments?.premiumPercentage);

    if (premiumPercentage) {
      adjustedUnitCost = unitCost * (percentOfProject / 100) * (1 + premiumPercentage / 100);
      adjustedUnitCost += unitCost * ((100 - percentOfProject) / 100);
    }

    return adjustedUnitCost;
  } catch (err) {
    throw Error(err);
  }
};

const getCompFieldUnitCost = (compUid, fieldUid, estimate) => {
  try {
    const comp = estimate.config.data.compSetting.root.comps[compUid];
    const field = estimate.fields.data[fieldUid];

    // This is a result of shit data fetching/data design
    if (!estimate.fieldCompDesignValues[fieldUid]) {
      return 0;
    }

    const fieldCompDesignValue = estimate.fieldCompDesignValues[fieldUid][compUid];
    let totalCost = field.comps[comp.ruid]?.cr_field_value;

    if (comp.ruid === '00000000000000000000000000000000') {
      // For Market data points, Unit Cost and Escalation are already calculated in the backend.

      const fieldRecCost = estimate.selectedRecCostFields[fieldUid]?.['00000000000000000000000000000000'];
      const fieldPremiumPercentage = fieldRecCost?.cr_field_premium || 0;

      if (fieldPremiumPercentage) {
        totalCost = (totalCost / (1 + (comp?.isPremium && comp?.premiumPercentage ? parseFloat(comp?.premiumPercentage) : 0) / 100)) * (1 + fieldPremiumPercentage / 100);
      }

      return parseFloat(totalCost);
    }

    // For Company Data points, calculate Unit Cost and Escalation accordingly ...
    const effectiveEscalation = parseFloat(estimate.effectiveEscalation[comp.ruid] || 0) / 100;
    let escalatedUnitCost = (parseFloat(totalCost) / parseFloat(fieldCompDesignValue.value)) * (1 + effectiveEscalation);

    const compRecUid = comp.ruid;
    const fieldRecCost = estimate.selectedRecCostFields[fieldUid]?.[compRecUid];

    let premium = 0;
    if (fieldRecCost?.cr_field_premium) {
      premium = fieldRecCost.cr_field_premium;
    } else if (comp.isPremium && comp.premiumPercentage) {
      premium = comp.premiumPercentage;
    }

    if (premium) {
      escalatedUnitCost = escalatedUnitCost * (1 + parseFloat(premium) / 100);
    }

    let msaPremium = 0;
    if (comp.isMsaPremium && comp.msaPremiumPercentage) {
      const { calculatedMsaTotal } = getCompRegionalAdjustments(comp);
      msaPremium = calculatedMsaTotal;
    }

    if (msaPremium) {
      escalatedUnitCost = escalatedUnitCost * (1 + parseFloat(msaPremium) / 100);
    }

    return parseFloat(escalatedUnitCost);
  } catch (err) {
    throw Error(err);
  }
};

const getFieldCost = (fieldUid, estimate, detailedEstimateTotals) => {
  try {
    const field = estimate.fields.data[fieldUid];
    const fieldCalculation = estimate.fieldCalculations[fieldUid];
    const detailedEstimate = estimate.detailedEstimates[fieldUid];
    const quantity = getFieldQuantity(fieldUid, estimate);

    if (detailedEstimate?.detailEstActive) {
      let totalCost = 0;

      for (const subFieldUid in detailedEstimate.subFields) {
        if (detailedEstimate.subFields[subFieldUid].isActive) {
          totalCost += detailedEstimateTotals[subFieldUid].total;
        }
      }

      return { total: totalCost, unit: safeDivide(totalCost, quantity), quantity };
    }

    let unitCost = 0;
    let escalatedUnitCost = 0;
    const isFieldsOverrideEscalationEnabled = estimate.config.data.costSetting.root.fieldOverridesEscalation?.enabled;
    const isManualField = fieldCalculation.unitCost?.manual;

    if (isManualField) {
      unitCost = parseFloat(fieldCalculation.unitCost.value || 0);

      if (isFieldsOverrideEscalationEnabled) {
        const calculatedEscalationRate = parseFloat((estimate.config.data.costSetting.root.fieldOverridesEscalation.calculatedEscalationRate || 0) / 100);
        escalatedUnitCost = parseFloat(unitCost * (1 + calculatedEscalationRate));
      }
    } else {
      unitCost = getFieldUnitCost(fieldUid, estimate);
    }

    if (estimate.isConsolidated) {
      return { total: parseFloat(field.meta?.consolidated_cost || 0), unit: unitCost, quantity };
    }

    let unitCostToCalculateTotalCost = 0;
    if (isFieldsOverrideEscalationEnabled && isManualField) {
      unitCostToCalculateTotalCost = escalatedUnitCost;
    } else {
      unitCostToCalculateTotalCost = unitCost;
    }

    return { total: quantity * unitCostToCalculateTotalCost, unit: unitCost, quantity, escalatedUnitCost };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    throw Error(err);
  }
};

const getDetailedEstimateSubFieldCost = (subFieldUid, fieldUid, estimate) => {
  try {
    const subField = estimate.detailedEstimates[fieldUid]?.subFields[subFieldUid] || {};
    const uomType = subField.selectedUomType;
    const selectedUom = subField.selectedUom;
    const unitCost = parseFloat(subField.unitCost || 0);
    let quantity = 0;

    switch (uomType) {
      case 'custom_value':
        quantity = parseFloat(subField.quantity || 0);
        break;
      case 'custom_design_field':
        quantity = parseFloat(estimate.config.data.programming.customDesignFields[selectedUom]?.value || 0);
        break;
      case 'custom_programming_field':
      case 'standard':
      default:
        quantity = parseFloat(estimate.estimateDesignValues[selectedUom] || 0);
        break;
    }

    return { total: quantity * unitCost, quantity };
  } catch (err) {
    throw Error(err);
  }
};

const getCompDesignValue = (uom, uomType, compUid, estimate) => {
  try {
    const comp = estimate.config.data.compSetting.root.comps[compUid];
    const compStandardFields = estimate.estimateCompsDesignFields.data.standardFields[comp.ruid] || {};
    const compNonStandardFields = estimate.estimateCompsDesignFields.data.nonStandardFields[comp.ruid] || [];
    const customDesignFields = estimate.config.data.programming.customDesignFields;
    const designRecordFields = estimate.designRecordFields.fields;

    let name = '';
    let value = '';

    if (uom === 'custom_value') {
      return { name, value };
    }

    if (uomType === 'custom_design_field') {
      const customDesignField = customDesignFields[uom];
      if (customDesignField) {
        const field_name = customDesignField.field_name.trim().toLowerCase();

        if (customDesignField.standard_field_uid) {
          const compDesignField = compStandardFields[customDesignField.standard_field_uid];
          if (compDesignField) {
            name = compDesignField.field_name;
            value = compDesignField.value;
          }
        } else {
          let matchedDesignField = null;

          for (const field of compNonStandardFields) {
            const field_name_lowercase = field.field_name.trim().toLowerCase();

            if (field_name_lowercase === field_name) {
              matchedDesignField = field;
              break;
            }

            if (!matchedDesignField && field_name_lowercase.includes(field_name)) {
              matchedDesignField = field;
            }
          }

          if (matchedDesignField) {
            name = matchedDesignField.field_name;
            value = matchedDesignField.value;
          }
        }
      }
    }
    if (uomType === 'custom_programming_field') {
      const customProgrammingField = designRecordFields[uom];
      if (customProgrammingField) {
        const field_name = customProgrammingField.field_name.trim().toLowerCase();

        if (customProgrammingField.standard_field) {
          const compDesignField = compStandardFields[customProgrammingField.standard_field];
          if (compDesignField) {
            name = compDesignField.field_name;
            value = compDesignField.value;
          }
        } else {
          let matchedDesignField = null;

          for (const field of compNonStandardFields) {
            const field_name_lowercase = field.field_name.trim().toLowerCase();

            if (field_name_lowercase === field_name) {
              matchedDesignField = field;
              break;
            }

            if (!matchedDesignField && field_name_lowercase.includes(field_name)) {
              matchedDesignField = field;
            }
          }

          if (matchedDesignField) {
            name = matchedDesignField.field_name;
            value = matchedDesignField.value;
          }
        }
      }
    } else {
      const compDesignField = compStandardFields[uom];
      if (compDesignField) {
        name = compDesignField.field_name;
        value = compDesignField.value;
      }
    }

    return { name, value };
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log(err);
    throw Error(err);
  }
};

const getFieldCompDesignValuesAndErrors = (fieldUid, estimate) => {
  const comps = estimate.config.data.compSetting.root.comps;
  const fieldCalculation = estimate.fieldCalculations[fieldUid];
  const compUids = Object.keys(comps);
  const fieldCompDesignValues = {};
  const fieldErrors = [];

  for (const compUid of compUids) {
    const comp = comps[compUid];
    if (comp.comp_category === 'company_data') {
      const designValue = getCompDesignValue(fieldCalculation.selected_uom, fieldCalculation.selected_uom_type, compUid, estimate);
      fieldCompDesignValues[compUid] = designValue;
      if (fieldCalculation.selected_uom !== 'custom_value' && !designValue.name) {
        fieldErrors.push(compUid);
      }
    }
  }

  return { fieldCompDesignValues, fieldErrors };
};

const getBucketCompDesignValues = (bucketUid, estimate) => {
  const comps = estimate.config.data.compSetting.root.comps;
  const bucketCalculation = estimate.bucketCalculations[bucketUid];
  const compUids = Object.keys(comps);
  const bucketCompDesignValues = {};

  for (const compUid of compUids) {
    const comp = comps[compUid];
    if (comp.comp_category === 'company_data') {
      bucketCompDesignValues[compUid] = getCompDesignValue(bucketCalculation.selected_uom, bucketCalculation.selected_uom_type, compUid, estimate);
    }
  }

  return bucketCompDesignValues;
};
const getIndirectCost = (indirectCostUid, indirectCostTotals, estimate) => {
  const indirectCost = estimate.config.data.costSetting.root.indirectCosts[indirectCostUid];
  const value = indirectCost.value || 0;

  if (indirectCost.calculation_type === INDIRECT_COSTS_CALCULATION_TYPES.DOLLAR) {
    return value;
  } else if (indirectCost.calculation_type === INDIRECT_COSTS_CALCULATION_TYPES.PERCENT) {
    let costs = estimate.totalDirectCost;

    if (indirectCost.appliesToCostCategories?.length > 0) {
      const bucketUids = indirectCost.appliesToCostCategories;
      bucketUids.forEach((bucketUid) => {
        const fieldUids = estimate.fields.buckets[bucketUid];
        fieldUids.forEach((fieldUid) => {
          costs += estimate.fieldCalculationTotals[fieldUid].total;
        });
      });
    }

    switch (indirectCost.formula_type) {
      case INDIRECT_COSTS_FORMULA_TYPES.PERCENT_OF_DIRECT_COST:
        return costs * (value / 100);
      case INDIRECT_COSTS_FORMULA_TYPES.PERCENT_OF_DIRECT_COST_PLUS_INSURANCE: {
        const insuranceUid = Object.keys(estimate.config.data.costSetting.root.indirectCosts).find(
          (indirectCostUid) => estimate.config.data.costSetting.root.indirectCosts[indirectCostUid].code === '99.0'
        );
        const insuranceValue = indirectCostTotals[insuranceUid];
        return (costs + insuranceValue) * (value / 100);
      }
      case INDIRECT_COSTS_FORMULA_TYPES.PERCENT_OF_DIRECT_COST_PLUS_GC_FEE: {
        const gcFeeUid = Object.keys(estimate.config.data.costSetting.root.indirectCosts).find(
          (indirectCostUid) => estimate.config.data.costSetting.root.indirectCosts[indirectCostUid].code === '99.1'
        );
        const gcValue = indirectCostTotals[gcFeeUid];
        return (costs + gcValue) * (value / 100);
      }
      case INDIRECT_COSTS_FORMULA_TYPES.PERCENT_OF_DIRECT_COST_PLUS_CONTINGENCY: {
        const contingencyUid = Object.keys(estimate.config.data.costSetting.root.indirectCosts).find(
          (indirectCostUid) => estimate.config.data.costSetting.root.indirectCosts[indirectCostUid].code === '99.2'
        );
        const contingencyValue = indirectCostTotals[contingencyUid];
        return (costs + contingencyValue) * (value / 100);
      }
      default:
        throw new Error('Invalid formula type');
    }
  }

  throw new Error('Invalid calculation type');
};

const getTotalDirectCost = (estimate) => {
  return Object.values(estimate.fieldCalculationTotals).reduce((total, fieldData) => total + fieldData.total, 0);
};

const getTotalIndirectCosts = (estimate) => {
  const indirectCosts = estimate.config.data.costSetting.root.indirectCosts || {};
  const indirectCostUids = Object.keys(indirectCosts);

  const { totalIndirectCost, newIndirectCosts } = indirectCostUids.reduce(
    (acc, indirectCostUid) => {
      const cost = getIndirectCost(indirectCostUid, acc.newIndirectCosts, estimate);
      acc.newIndirectCosts[indirectCostUid] = cost;
      acc.totalIndirectCost += cost;
      return acc;
    },
    { totalIndirectCost: 0, newIndirectCosts: {} }
  );

  return { indirectCosts: newIndirectCosts, totalIndirectCost };
};

const getMarketCompFilterConfig = (comp) => {
  const filterConfig = { ...defaultFilterConfig };

  if (comp.projectType && comp.projectType.length) {
    filterConfig.projectType = {
      enabled: true,
      data: { selected: comp.projectType }
    };
  } else {
    filterConfig.projectType = {
      enabled: false,
      data: { selected: [] }
    };
  }

  if (comp.productType && comp.productType.length) {
    filterConfig.productType = {
      enabled: true,
      data: { selected: comp.productType }
    };
  } else {
    filterConfig.productType = {
      enabled: false,
      data: { selected: [] }
    };
  }

  if (comp.constructionType && comp.constructionType.length) {
    filterConfig.constructionType = {
      enabled: true,
      data: { selected: comp.constructionType }
    };
  } else {
    filterConfig.constructionType = {
      enabled: false,
      data: { selected: [] }
    };
  }

  if (comp.productRegions && comp.productRegions.length) {
    filterConfig.region = {
      enabled: true,
      data: { regions: comp.productRegions }
    };
  } else {
    filterConfig.region = {
      enabled: false,
      data: { regions: [] }
    };
  }

  if (comp.buyoutTime) {
    filterConfig.buyoutTime = {
      enabled: true,
      data: { selected: [comp.buyoutTime] }
    };
  } else {
    filterConfig.buyoutTime = {
      enabled: false,
      data: { selected: [] }
    };
  }

  if (comp.projectStatus && comp.projectStatus.length) {
    filterConfig.projectStatus = {
      enabled: true,
      data: { selected: comp.projectStatus }
    };
  } else {
    filterConfig.projectStatus = {
      enabled: false,
      data: { selected: [] }
    };
  }

  if (comp.rentalType && comp.rentalType.length) {
    filterConfig.rentalType = {
      enabled: true,
      data: { selected: comp.rentalType }
    };
  } else {
    filterConfig.rentalType = {
      enabled: false,
      data: { selected: [] }
    };
  }

  if (comp.union) {
    filterConfig.union = {
      enabled: true,
      data: { selected: [comp.union] }
    };
  } else {
    filterConfig.union = {
      enabled: false,
      data: { selected: [] }
    };
  }

  if (comp.avgUnitSize) {
    filterConfig.avgUnitSize = {
      enabled: true,
      data: comp.avgUnitSize
    };
  } else {
    filterConfig.avgUnitSize = {
      enabled: false,
      data: defaultFilterConfig.avgUnitSize.data
    };
  }

  if (comp.unitCount) {
    filterConfig.unitCount = {
      enabled: true,
      data: comp.unitCount
    };
  } else {
    filterConfig.unitCount = {
      enabled: false,
      data: defaultFilterConfig.unitCount.data
    };
  }

  return filterConfig;
};

const getFieldsFromEstimateProgrammingConfig = (estimateProgrammingConfigGroups) => {
  const fieldUids = [];
  Object.keys(estimateProgrammingConfigGroups).forEach((groupUid) => {
    const group = estimateProgrammingConfigGroups[groupUid];
    Object.keys(group.estimate_programming_config_items).forEach((itemUid) => {
      const item = group.estimate_programming_config_items[itemUid];
      fieldUids.push(item.field_uid);
    });
  });
  return fieldUids;
};

const getCompRegionalAdjustments = (comp) => {
  const compMsaPremium = parseFloat(comp.msaPremiumPercentage || 0);
  const projectMsaPremium = parseFloat(comp.projectMsaPremiumPercentage || 0);
  const msaTotal = (1 + projectMsaPremium / 100) / (1 + compMsaPremium / 100);
  const calculatedMsaTotal = (msaTotal - 1) * 100;

  return {
    compMsaPremium,
    projectMsaPremium,
    msaTotal,
    calculatedMsaTotal
  };
};

export {
  convertOldConfigData,
  convertOldDetailedEstimates,
  convertOldFieldCalculations,
  detailedEstimateTemplate,
  detailedEstimateSubFieldTemplate,
  safeDivide,
  indirectCostTemplate,
  getEstimateDesignValue,
  getDivisionDefaultUom,
  getBucketQuantity,
  getFieldQuantity,
  getFieldUnitCost,
  getCompFieldUnitCost,
  getFieldCost,
  getDetailedEstimateSubFieldCost,
  getFieldCompDesignValuesAndErrors,
  getBucketCompDesignValues,
  getIndirectCost,
  getTotalDirectCost,
  getTotalIndirectCosts,
  getMarketCompFilterConfig,
  getFieldsFromEstimateProgrammingConfig,
  getCompRegionalAdjustments
};

// Fake client side enums
// Eventuall we will replace this with actual enums from the database
export const INDIRECT_COSTS_FORMULA_TYPES = {
  PERCENT_OF_DIRECT_COST: 'PERCENT_OF_DIRECT_COST',
  PERCENT_OF_DIRECT_COST_PLUS_INSURANCE: 'PERCENT_OF_DIRECT_COST_PLUS_INSURANCE',
  PERCENT_OF_DIRECT_COST_PLUS_GC_FEE: 'PERCENT_OF_DIRECT_COST_PLUS_GC_FEE',
  PERCENT_OF_DIRECT_COST_PLUS_CONTINGENCY: 'PERCENT_OF_DIRECT_COST_PLUS_CONTINGENCY'
};

export const INDIRECT_COSTS_FORMULA_TYPES_LABELS = {
  PERCENT_OF_DIRECT_COST: '% of direct cost',
  PERCENT_OF_DIRECT_COST_PLUS_INSURANCE: '% of direct cost + insurance',
  PERCENT_OF_DIRECT_COST_PLUS_GC_FEE: '% of direct cost + GC fee',
  PERCENT_OF_DIRECT_COST_PLUS_CONTINGENCY: '% of direct cost + contingency'
};

export const INDIRECT_COSTS_CALCULATION_TYPES = {
  PERCENT: 'PERCENT',
  DOLLAR: 'DOLLAR'
};

export const API_STATUS_ENUM = {
  IDLE: 'IDLE',
  PENDING: 'PENDING',
  SUCCESS: 'SUCCESS',
  ERROR: 'ERROR'
};

// Actual database enums
export const ESTIMATE_OVERVIEW_CONFIG_ITEM_TYPE = {
  FIELD: 'FIELD',
  GENERAL_INFO: 'GENERAL_INFO'
};

export const ESTIMATE_OVERVIEW_CONFIG_ITEM_GENERAL_INFO_TYPE = {
  PROJECT_TYPE: 'PROJECT_TYPE',
  PROJECT_ADDRESS: 'PROJECT_ADDRESS',
  PROJECT_STATUS: 'PROJECT_STATUS',
  RECORD_TYPE: 'RECORD_TYPE',
  CONSTRUCTION_TYPE: 'CONSTRUCTION_TYPE'
};

export const ESTIMATE_OVERVIEW_CONFIG_ITEM_GENERAL_INFO_TYPE_LABELS = {
  PROJECT_TYPE: 'Project Type',
  PROJECT_ADDRESS: 'Project Address',
  RECORD_TYPE: 'Record Type',
  PROJECT_STATUS: 'Project Status',
  CONSTRUCTION_TYPE: 'Construction Type'
};

export const PROJECT_TYPE_LABELS = {
  multifamily: 'Mixed-Use Multifamily',
  student_housing: 'Student Housing',
  senior_housing: 'Senior Housing',
  singlefamily: 'Single Family',
  industrial: 'Industrial',
  hospital: 'Hospital',
  school: 'School',
  storage: 'Storage',
  life_sciences: 'Life Sciences',
  office: 'Office',
  hotel: 'Hotel',
  various: 'Various',
  religion: 'Religion'
};

export const PROJECT_STATUS_LABELS = {
  'quick-estimate': 'Conceptual',
  'schematic-design': 'Schematic Design',
  'pre-construction': 'Design Development',
  'construction-documents': 'Construction Documents',
  contract: 'Contract',
  'in-construction': 'In-Construction',
  completed: 'Completed'
};

export const PRODUCT_TYPE_LABELS = {
  podium: 'Podium',
  wrap: 'Wrap',
  slab_on_grade: 'Slab On Grade',
  wood_podium: 'Wood Podium',
  various: 'Various',
  high_rise: 'High Rise',
  warehouse: 'Warehouse',
  light_manufacturing: 'Light Manufacturing',
  heavy_manufacturing: 'Heavy Manufacturing',
  cold_storage: 'Cold Storage'
};

import { Box, LinearProgress, Alert } from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { useLocation, Link } from 'react-router-dom';
import { ErrorBoundary } from '@rollbar/react';

import ErrorDisplay from './ErrorDisplay';

const Page = ({ children }) => {
  return (
    <Box display="flex" minHeight="var(--page-height)" pt="var(--app-header-height)">
      {children}
    </Box>
  );
};

const PageLoading = () => {
  return (
    <Box pt="var(--app-header-height)">
      <LinearProgress />
    </Box>
  );
};

const PageError = ({ children }) => {
  return (
    <Box minHeight="var(--page-height)" pt="var(--app-header-height)">
      <Box p={2}>
        <Alert severity="error">{children}</Alert>
      </Box>
    </Box>
  );
};

const PageSidebar = ({ children }) => {
  return (
    <Box width="var(--page-sidebar-width)" minWidth="var(--page-sidebar-width)" bgcolor="white" borderRight="1px solid var(--zcolor-lighter-gray)" zIndex={1} px={1}>
      <Box position="sticky" top="var(--app-header-height)" maxHeight="var(--page-height)" overflow="auto" py={1}>
        {children}
      </Box>
    </Box>
  );
};

const PageContent = ({ children }) => {
  return (
    <Box p={2} flex="auto">
      <ErrorBoundary fallbackUI={ErrorDisplay}>{children}</ErrorBoundary>
    </Box>
  );
};

const PageContentLoading = () => {
  return (
    <Box flex="auto">
      <LinearProgress />
    </Box>
  );
};

const PageContentError = ({ children }) => {
  return (
    <Box p={2} flex="auto">
      <Alert severity="error">{children}</Alert>
    </Box>
  );
};

const PageSidebarLink = ({ to, children }) => {
  const location = useLocation();

  const isActive = `${location.pathname}${location.search}`.startsWith(to);

  return (
    <Box
      display="flex"
      className="truncate"
      px={1}
      alignItems="center"
      fontSize={12}
      letterSpacing={0.4}
      fontWeight={500}
      height={32}
      mb={0.25}
      sx={{ transition: 'all 0.2s ease-in-out', '&:hover': { background: 'var(--zcolor-lightest-gray)' } }}
      component={Link}
      borderRadius="3px"
      to={to}
      bgcolor={isActive ? 'var(--zcolor-lightest-gray)' : 'transparent'}
    >
      <Box width="100%" sx={{ textTransform: 'uppercase' }}>
        {children}
      </Box>
    </Box>
  );
};

const PageSidebarGroupLink = ({ to, children, isExpanded }) => {
  return (
    <PageSidebarLink to={to}>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Box>{children}</Box>
        <Box fontSize={16} sx={{ transform: isExpanded ? `rotate(90deg)` : `rotate(0deg)`, transition: 'all .2s ease-in-out' }} display="flex">
          <ChevronRight fontSize="inherit" />
        </Box>
      </Box>
    </PageSidebarLink>
  );
};

const PageSidebarSecondaryLink = ({ to, children }) => {
  const isActive = location.pathname.startsWith(to);

  return (
    <Box
      display="flex"
      className="truncate"
      mb={0.25}
      px={1}
      ml={1}
      alignItems="center"
      fontSize={13}
      letterSpacing={0.4}
      height={26}
      sx={{ transition: 'all 0.2s ease-in-out', '&:hover': { background: 'var(--zcolor-lightest-gray)' } }}
      component={Link}
      borderRadius="3px"
      to={to}
      bgcolor={isActive ? 'var(--zcolor-lightest-gray)' : 'transparent'}
    >
      <Box width="100%">{children}</Box>
    </Box>
  );
};

export { Page, PageLoading, PageError, PageSidebar, PageContent, PageContentLoading, PageContentError, PageSidebarLink, PageSidebarGroupLink, PageSidebarSecondaryLink };

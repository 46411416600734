import moment from 'moment';

const initialManualItems = {
  onGrade: false,
  onStructure: false,
  concreteGSF: false,
  WGSF: false,
  parkingStalls: false,
  parkingArea: false,
  residentialBuildingFootprint: false,
  GLA: false,
  constructionDuration: false,
  lotSize: false,
  garageFootprint: false,
  efficiency: true
};

const defaultProgrammingConfig = {
  studio: { label: 'Studio', isUnit: true, area: 500, count: 100, bathrooms: 1, wasteoutlets: 5 },
  oneBedroom: { label: '1 Bedroom', isUnit: true, area: 750, count: 100, bathrooms: 1, wasteoutlets: 5 },
  twoBedroom: { label: '2 Bedroom', isUnit: true, area: 1000, count: 100, bathrooms: 2, wasteoutlets: 8 },
  threeBedroom: { label: '3 Bedroom', isUnit: true, area: 1250, count: 100, bathrooms: 2, wasteoutlets: 8 },
  fourBedroom: { label: '4 Bedroom', isUnit: true, area: 1500, count: 100, bathrooms: 3, wasteoutlets: 11 },
  fiveBedroom: { label: '5 Bedroom', isUnit: true, area: 1750, count: 100, bathrooms: 3, wasteoutlets: 11 },
  woodBuilding: {
    label: 'Residential Building',
    isUnit: false,
    efficiency: 74,
    linerGSF: 0,
    WGSF: 0,
    numWoodLevels: 4,
    numConcreteLevels: 0,
    residentialBuildingFootprint: 0
  },
  garage: { label: 'Concrete Garage', isUnit: false, ratio: 2, efficiency: 400, parkingStalls: 0, levels: 1, concreteGSF: 0, parkingArea: 0 },
  woodGarage: {
    label: 'Wood Garage',
    isUnit: false,
    woodGarageStandAlone: 0,
    woodGarageStandAloneStalls: 0,
    woodGarageTuckUnderSF: 0,
    woodGarageTuckUnderStalls: 0
  },
  surfaceParking: {
    label: 'Surface Parking',
    isUnit: false,
    surfaceParkingStalls: 0
  },
  openCommonArea: { label: 'Open Common Area', isUnit: false, onGrade: 0, onStructure: 0, streetsTotalSF: 0, poolSpaSF: 0 },
  retail: { label: 'Retail', isUnit: false, area: 0 },
  recLeasing: { label: 'Rec/Leasing', isUnit: false, area: 0 }
};

const stdDesignFields = () => {
  const commonAreaTitle = 'Common Area Open';
  // const commonAreaTitle = isHighrise ? "Landscape" : "Common Area Open";
  return [
    { field_name: 'Bathroom (Full)', uid: '14e5d4f915c546fa9ce9ad91a764c8ff', unit: 'EA' },
    { field_name: 'Bathroom (Half)', uid: '42ec1048e4cd430999e3687e191bf98c', unit: 'EA' },
    { field_name: 'Bathroom (Total)', uid: 'f254dffd142740718c05982c5cfab85a', unit: 'EA' },
    { field_name: 'Bedroom', uid: 'b27db6f1c6a54758b671bb2d39b902c6', unit: 'EA' },

    { field_name: 'Studio (Total)', uid: 'e5416d6979714e2cb70bbb65f5701a2a', unit: 'EA', excludeFromUom: true },
    { field_name: 'Studio %', uid: 'b2215789a3844f18aee9c2af89197046', unit: '%', excludeFromUom: true },
    { field_name: '1 Bedroom (Total)', uid: '332aecb41fe24e2a9fdbe1f7188f131d', unit: 'EA', excludeFromUom: true },
    { field_name: '1 Bedroom %', uid: 'b2fab0d3351349ad9986c85419ec76cf', unit: '%', excludeFromUom: true },
    { field_name: '2 Bedroom (Total)', uid: '8a6d8365738b4efc9340e5b182ab4036', unit: 'EA', excludeFromUom: true },
    { field_name: '2 Bedroom %', uid: '78078ac87c144037abcadb50406d5019', unit: '%', excludeFromUom: true },
    { field_name: '3 Bedroom (Total)', uid: '2ffe4f876e9942089982ab785cc72eb1', unit: 'EA', excludeFromUom: true },
    { field_name: '3 Bedroom %', uid: '8ca30162fbc642bd947e6a3b2d087f53', unit: '%', excludeFromUom: true },
    { field_name: '4 Bedroom (Total)', uid: '3f6be64b9aab4f2c9c3cb0b7759d24b7', unit: 'EA', excludeFromUom: true },
    { field_name: '4 Bedroom %', uid: '1aba41d496ff462e8861ac0eb049ed65', unit: '%', excludeFromUom: true },
    { field_name: '5 Bedroom (Total)', uid: 'f183777429b94af79bc331d28a20370b', unit: 'EA', excludeFromUom: true },
    { field_name: '5 Bedroom %', uid: '418c38e05e234111b125d9393b446f4e', unit: '%', excludeFromUom: true },

    // This has to have "excludeFromUom: false" since we need this Design metrics written to the Record Fields in DB
    // If not, some chart may not  show this.
    { field_name: 'Average Unit Size', uid: '295384e3b3c04b5bb221f8ea80522d0a', unit: 'SF', excludeFromUom: false },

    {
      field_name: `${commonAreaTitle} SF (On Grade)`,
      uid: '1b9ebaf01e64448db3be54af43f5f13d',
      unit: 'SF',
      manual_item_name: 'onGrade',
      description:
        'On grade portion of the landscape and hardscape areas left outside of the building footprint and streets. Formula: Lot Size (Net) - Podium Footprint (On Grade Level)[for podium] - Garage Footprint (Lowest Level) [for wrap/SOG] - Residnetial Building Footprint [for SOG/Wrap]'
    },
    {
      field_name: `${commonAreaTitle} SF (On Structure)`,
      uid: '7b0d37e33ea34475b374f939666b7486',
      unit: 'SF',
      manual_item_name: 'onStructure',
      description:
        'Landscape and hardscape area in podium projects above the concrete garage. Formula: podiumFootprint (On Grade Level) - Residential Building Footprint + (Stand alone Retail on the podium+ Stand alone Rec/Lease on podium)'
    },
    { field_name: `${commonAreaTitle} Acre (Total)`, uid: '8c71a13beea24146a79323e498544572', unit: 'Acre' },
    { field_name: `${commonAreaTitle} SF (Total)`, uid: 'e29af06f5cd4473a9c1b702d094a4bcf', unit: 'SF' },
    {
      field_name: 'Parking Area',
      uid: '8f0d071974734d89b1d480a9b7f7cd23',
      unit: 'SF',
      description:
        'Areas where cars are parked plus all misc. areas within concrete garage structure (bike area, storage, electrical room, parking lobbies, etc). Excludes rec/lease and retail in concrete. Excludes Stair Area constructed of Wood. Excludes podium slab in podium projects',
      manual_item_name: 'parkingArea'
    },
    { field_name: `${commonAreaTitle} SF (On Grade) Per Lot Size (Gross)`, uid: '9a7605dbd0cb4331be7afe36707d8a17', unit: '%', excludeFromUom: true },
    {
      field_name: 'Concrete Structure GSF',
      uid: '941e018ca40542338ef7cf37cfe51334',
      unit: 'SF',
      description: ' Total concrete structure SF, including the parking garage, any retail and rec/leasing areas in concrete. Includes the podium slab in podium projects',
      manual_item_name: 'concreteGSF'
    },
    { field_name: 'Construction Duration (Building)', uid: 'd8b2d78a40024fad80060823e7d1fc28', unit: 'Months' },
    {
      field_name: 'GSF (Liner Unit)',
      uid: '0eec9102b16e47eaac1ab7cf0c99ae59',
      unit: 'SF',
      description: 'Gross SF of the residential building built in concrete with metal studs, including the apartment units, corridors, lobbies, etc.'
    },
    { field_name: 'Lot Size (Gross SF)', uid: '9a32223adbe14a158f358635a412080d', unit: 'SF' },
    { field_name: 'Lot Size (Net Acre)', uid: 'e642f31ef59a48e9a2aea8d78472867c', unit: 'Acre' },

    { field_name: 'Lot Size (Net SF)', uid: '4c7cd8cc001842b78922d04d1ec851d4', unit: 'SF' },
    { field_name: 'Lot Size (Gross Acre)', uid: '99705094935c42c29917436a20f62bbd', unit: 'Acre' },

    { field_name: 'NRSF', uid: '22539d6e2857419ea6e10927d74de3f5', unit: 'SF' },
    { field_name: 'Pool / Spa SF', uid: '858e6c857f7843168fa9d160fff62598', unit: 'SF' },
    { field_name: 'Rec/Leasing SF (Total)', uid: 'd561f238baec4e199c7cc7a9bc33c8f6', unit: 'SF' },
    {
      field_name: 'Residential Building Footprint',
      uid: '8f452c6e4ed24cb39fd89357791735f1',
      unit: 'SF',
      description: 'Includes Residential Building Footprint + Stand alone Retail & Rec/Lease (Does not include garage)',
      manual_item_name: 'residentialBuildingFootprint'
    },
    {
      field_name: 'Garage Footprint',
      uid: '2e135285c7b843269e6fdf8034ca0419',
      unit: 'SF',
      description: 'Garage Footprint is calculated by the total parking area / parking levels',
      manual_item_name: 'garageFootprint'
    },
    { field_name: 'Retail SF (Total)', uid: '01b7e4f8e0844eb39cda1287b958f016', unit: 'SF' },
    {
      field_name: 'Streets (Total SF)',
      uid: 'b644afd98a254a1982ab0c363a7b677f',
      unit: 'SF',
      description: 'Private and public streets to be built and/or improved as part of the project scope'
    },
    { field_name: 'Unit', uid: 'cfad0164f8854f36ad52d34ed29f01d9', unit: 'EA' },
    { field_name: 'Waste Outlet (Total)', uid: '06929a607473493abfe80aa68784c289', unit: 'EA' },
    { field_name: 'Windowroom', uid: '4120274c82e749f69f8e5d07806203bc', unit: 'EA' },
    {
      field_name: 'WGSF',
      uid: 'dc9922f630c5495ba08953479877845c',
      unit: 'SF',
      manual_item_name: 'WGSF',
      description:
        'Gross Square Footage of the wood framed structure, including any retail or rec/leasing components. Excludes liner units and any areas built in concrete. Double volume spaces are to be included regardless of missing floor. (Example: 1000 sq/ft Fitness dbl height= 2000 sq/ft)'
    },
    { field_name: 'WGSF + Liner GSF', uid: 'c6e70f2f69444d508387f1cf135afb86', unit: 'SF', manual_item_name: 'GLA' },
    {
      field_name: 'Wood Garage SF (Stand Alone)',
      uid: 'c88f585b422a470a9701149ed678700f',
      unit: 'SF',
      description: 'Enclosed standalone wood garage areas in slab-on-grade (walk-up/garden style) projects'
    },
    {
      field_name: 'Wood Garage Stalls (Stand Alone)',
      uid: '64730e8c2e154ec6a90b9a73a52570cc',
      unit: 'EA',
      description: 'Number of Stalls for enclosed standalone wood garage'
    },
    {
      field_name: 'Wood Garage Tuck Under SF',
      uid: '5ed9f6112eee49c0a27e4d08050c8048',
      unit: 'SF',
      description: 'Square footage of Tuck Under Wood Garage'
    },
    {
      field_name: 'Wood Garage Tuck Under Stalls',
      uid: '2cee1ff74014401c9cf69adfe782cbae',
      unit: 'EA',
      description: 'Number of Tuck Under Wood Garage Stalls'
    },
    {
      field_name: 'Surface Parking Stalls',
      uid: '7fc4756ebec84d04b85f4841f72f4d4a',
      unit: 'EA',
      description: 'Number of Surface Parking Stalls'
    },
    { field_name: 'Total GSF', uid: 'abeabfb75f10436a9c44725897bb3e59', unit: 'SF' },

    // Not included in the Estimate line items' UOM list
    {
      field_name: 'Building Efficiency',
      uid: 'e9c776c1cb8c4fe4ae269ef6088db227',
      unit: '%',
      description: 'Net rentable SF over Gross SF of the wood framed building',
      excludeFromUom: true,
      manual_item_name: 'efficiency'
    },
    { field_name: 'Wood Levels', uid: 'a862f8cea7424768bfd2d3bea98254c2', unit: 'EA', description: 'Number of floors of the wood framed structure', excludeFromUom: true },
    {
      field_name: 'Concrete Levels',
      uid: '5e5eae9a45c2429ca2be5810af5ea386',
      unit: 'EA',
      description: 'Number of floors of the residential building built in concrete',
      excludeFromUom: true
    },
    {
      field_name: 'Parking Ratio',
      uid: '6c1cd092e7ee46049b1a16986daab0ea',
      description: 'Number of total parking stalls within the Concrete Garage per apartment unit',
      excludeFromUom: true,
      unit: 'Stall/Unit'
    },
    {
      field_name: 'Parking Stalls',
      uid: 'be8a38681b404ed0af238f6e0ab0e3af',
      description: 'Number of total parking stalls within the Concrete Garage',
      // excludeFromUom: true,
      unit: 'EA',
      manual_item_name: 'parkingStalls'
    },
    {
      field_name: 'Parking Efficiency',
      uid: 'f5cf9d08c409420fba46876ac9cb6e51',
      description: 'Parking Area / Number of parking stalls in the Concrete Garage',
      unit: 'SF/Stall',
      excludeFromUom: true
    },
    { field_name: 'Parking Levels', uid: '01731bb699664decb1ccba1faa9ee0b5', unit: 'EA', description: 'Number of floors of the Concrete Garage structure', excludeFromUom: true }
  ];
};

const designFields = {
  ConstructionDuration: 'd8b2d78a40024fad80060823e7d1fc28',
  LotSizeGrossSF: '9a32223adbe14a158f358635a412080d',
  LotSizeGrossAcre: '99705094935c42c29917436a20f62bbd',
  ConstructionType: 'ConstructionType',
  ProductType: 'ProductType',
  LotSizeUnit: 'LotSizeUnit',

  // Design Fields' multifamily Standard Field UIDs
  UnitCount: 'cfad0164f8854f36ad52d34ed29f01d9',
  NRSF: '22539d6e2857419ea6e10927d74de3f5',
  GLA: 'GLA',
  GBA: 'GBA',
  TotalGSF: 'abeabfb75f10436a9c44725897bb3e59',
  StudioCount: 'e5416d6979714e2cb70bbb65f5701a2a',
  StudioPercent: 'b2215789a3844f18aee9c2af89197046',
  OneBedroomCount: '332aecb41fe24e2a9fdbe1f7188f131d',
  OneBedroomPercent: 'b2fab0d3351349ad9986c85419ec76cf',
  TwoBedroomCount: '8a6d8365738b4efc9340e5b182ab4036',
  TwoBedroomPercent: '78078ac87c144037abcadb50406d5019',
  ThreeBedroomCount: '2ffe4f876e9942089982ab785cc72eb1',
  ThreeBedroomPercent: '8ca30162fbc642bd947e6a3b2d087f53',
  FourBedroomCount: '3f6be64b9aab4f2c9c3cb0b7759d24b7',
  FourBedroomPercent: '1aba41d496ff462e8861ac0eb049ed65',
  FiveBedroomCount: 'f183777429b94af79bc331d28a20370b',
  FiveBedroomPercent: '418c38e05e234111b125d9393b446f4e',
  AvgUnitSize: '295384e3b3c04b5bb221f8ea80522d0a',
  ParkingArea: '8f0d071974734d89b1d480a9b7f7cd23',
  WoodGarageStandAlone: 'c88f585b422a470a9701149ed678700f',
  WoodGarageStandAloneStalls: '64730e8c2e154ec6a90b9a73a52570cc',
  WoodGarageTuckUnderSF: '5ed9f6112eee49c0a27e4d08050c8048',
  WoodGarageTuckUnderStalls: '2cee1ff74014401c9cf69adfe782cbae',
  SurfaceParkingStalls: '7fc4756ebec84d04b85f4841f72f4d4a',
  ConcreteGSF: '941e018ca40542338ef7cf37cfe51334',
  CommonAreaOnGradeAcre: 'CommonAreaOnGradeAcre',
  CommonAreaOnStructureAcre: 'CommonAreaOnStructureAcre',
  CommonAreaOnGradeSF: '1b9ebaf01e64448db3be54af43f5f13d',
  CommonAreaOnStructureSF: '7b0d37e33ea34475b374f939666b7486',
  CommonAreaPerLotSize: '9a7605dbd0cb4331be7afe36707d8a17',
  RecLeasingSF: 'd561f238baec4e199c7cc7a9bc33c8f6',
  RetailSF: '01b7e4f8e0844eb39cda1287b958f016',
  WGSF: 'dc9922f630c5495ba08953479877845c',
  LinerGSF: '0eec9102b16e47eaac1ab7cf0c99ae59',
  BuildingEfficiency: 'e9c776c1cb8c4fe4ae269ef6088db227',
  ResidentialBuildingFootprint: '8f452c6e4ed24cb39fd89357791735f1',
  GarageFootprint: '2e135285c7b843269e6fdf8034ca0419',
  NumWoodLevels: 'a862f8cea7424768bfd2d3bea98254c2',
  NumConcreteLevels: '5e5eae9a45c2429ca2be5810af5ea386',
  ParkingRatio: '6c1cd092e7ee46049b1a16986daab0ea',
  ParkingStalls: 'be8a38681b404ed0af238f6e0ab0e3af',
  ParkingEfficiency: 'f5cf9d08c409420fba46876ac9cb6e51',
  ParkingStallsOverall: 'ParkingStallsOverall',
  ParkingRatioOverall: 'ParkingRatioOverall',
  ParkingLevels: '01731bb699664decb1ccba1faa9ee0b5',
  StreetsTotalSF: 'b644afd98a254a1982ab0c363a7b677f',
  PoolSpaSF: '858e6c857f7843168fa9d160fff62598',
  Bedrooms: 'b27db6f1c6a54758b671bb2d39b902c6',
  FullBathrooms: '14e5d4f915c546fa9ce9ad91a764c8ff',
  HalfBathrooms: '42ec1048e4cd430999e3687e191bf98c',
  TotalBathrooms: 'f254dffd142740718c05982c5cfab85a',
  WasteOutlets: '06929a607473493abfe80aa68784c289',
  WindowRooms: '4120274c82e749f69f8e5d07806203bc',
  WGSFPlusLinerGSF: 'c6e70f2f69444d508387f1cf135afb86',
  CommonAreaOpenAcreTotal: '8c71a13beea24146a79323e498544572',
  CommonAreaOpenSFTotal: 'e29af06f5cd4473a9c1b702d094a4bcf',

  // Pro forma
  RetailGrossIncomePerYear: 'RetailGrossIncomePerYear',
  RetailVacancyLossPerYear: 'RetailVacancyLossPerYear',
  RetailRent: 'RetailRent',
  RetailVacancyRate: 'RetailVacancyRate',
  AptGrossIncomePerYear: 'AptGrossIncomePerYear',
  AptVacancyLossPerYear: 'AptVacancyLossPerYear',
  AptVacancyRate: 'AptVacancyRate',
  EffectiveGrossIncome: 'EffectiveGrossIncome',
  LandAcqCost: 'LandAcqCost',
  OperatingExpenses: 'OperatingExpenses'
};

const initialEstimateConfig = {
  refresh: false,
  generalInformation: {
    root: {
      projectType: 'Mixed-Use Multifamily',
      date: moment().format('MM/DD/YYYY'),
      lotSizeUnit: 'Acre'
    }
  },
  compSetting: {
    root: {
      comps: {}
    }
  },
  costSetting: {
    root: {}
  },
  compareConfig: {
    root: {}
  },
  proFormaSetting: {
    root: {}
  },
  programming: {
    'Mixed-Use Multifamily': {
      unitMix: {}
    },
    customDesignFields: {},
    estimateProgrammingConfig: []
  },
  reportConfig: {},
  totalCostUOM: null,
  totalCostUOMType: null
};

const UoMStandardDesignFields = () => {
  const standardDesignFields = stdDesignFields(false)
    .filter((sf) => sf.field_name !== null && sf.excludeFromUom !== true)
    .filter((sf) => sf.uid !== '295384e3b3c04b5bb221f8ea80522d0a');

  let uoMStandardDesignFields = {};

  standardDesignFields.forEach((field) => {
    uoMStandardDesignFields[field.uid] = field;
  });

  return uoMStandardDesignFields;
};

// Initial FieldsUoms for 16 Divisions calculations of Market Unit Price
const divisionUids = [
  '088236d979c9457785428f1286c761bf',
  'e7c5aa724ae94b1f9d301edad82b6674',
  '471feee08b4b4360a0383ca2da2b429f',
  '394090abba4942e38b807c70b78476d9',
  'fedccebc4824404282717302dbd9fe84',
  'afa944eb7b404d9c995574c52a9a43b9',
  '3d43a20c47304411a665d1d0d047a34c',
  '82d74a3e0bed462fba66e3c0d08e2e20',
  '708226e167f14ff0b155fa9b44de1777',
  'ec5c36f7730b478e81850d46f60c1620',
  'f0f82f5960194f169a51ef03d867ce0e',
  '9787f88818c143a1bbb12ba770f73d79',
  '028c1dec0e3044d9ae0c62136ce11d3c',
  '22f83d0f7fad4cf49e136cbba687ec40',
  '2fa1acb919544c12a6b6c561d870d5cc',
  '87737e41cb9c4f4e8f5f61881b13987f'
];

export { defaultProgrammingConfig, initialEstimateConfig, initialManualItems, designFields, UoMStandardDesignFields, stdDesignFields, divisionUids };

import React from 'react';
import { Grid, Alert } from '@mui/material';
import Button from '@mui/material/Button';

const ErrorDisplay = ({ resetError }) => {
  return (
    <Grid container>
      <Grid item xs={12} style={{ padding: '50px' }}>
        <Alert
          severity="error"
          action={
            <Button color="inherit" size="small" onClick={resetError}>
              Try Again
            </Button>
          }
        >
          An error occurred.
        </Alert>
      </Grid>
    </Grid>
  );
};

export default ErrorDisplay;

import { Link } from 'react-router-dom';
import { Megaphone } from 'lucide-react';
import { useState } from 'react';
import { useDidMount } from 'rooks';
import { hasUnseenNews } from './_helpers';
import { Button } from '@/components/ui/button';

const ProductIcon = () => {
  const [unseenNews, setUnseenNews] = useState(false);

  useDidMount(() => {
    setUnseenNews(hasUnseenNews());
  });

  const handleHideNews = () => {
    setUnseenNews(false);
  };

  return (
    <Button asChild variant="ghost" size="sm">
      <Link to="/updates" className="relative" onClick={handleHideNews}>
        {unseenNews && <span className="absolute top-1 right-1 block h-2 w-2 rounded-full bg-zebel-primary-blue animate-pulse" />}
        <Megaphone />
        Updates
      </Link>
    </Button>
  );
};

export default ProductIcon;

import { SearchX, TriangleAlert } from 'lucide-react';
import { Alert, AlertDescription, AlertTitle } from '@/components/ui/alert';

const Error = ({ title = 'Error', message, status = 500 }) => {
  if (status === 404) {
    return (
      <div className="flex flex-col items-center justify-center min-h-[400px] space-y-1">
        <div className="rounded-full bg-muted p-3 border">
          <SearchX className="h-10 w-10" />
        </div>
        <div className="text-lg font-medium pt-1">{title}</div>
        <div className="text-muted-foreground">{message}</div>
      </div>
    );
  }

  return (
    <Alert variant="destructive">
      <TriangleAlert className="h-4 w-4" />
      <AlertTitle>{title}</AlertTitle>
      <AlertDescription>{message}</AlertDescription>
    </Alert>
  );
};

export { Error };
